import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';
import {
  RiSpeedUpLine,
  RiCameraLine,
  RiVipDiamondLine,
  RiFlightTakeoffLine,
  RiFlightLandLine,
  RiArrowUpDownLine,
  RiHome6Line,
  RiMapPin2Line,
} from 'react-icons/ri';

import DoChangeSpeed from './DoChangeSpeed';
import DoParachute from './DoParachute';
import DoSetCamTriggDist from './DoSetCamTriggDist';
import styles from './index.module.scss';
import NavLand from './NavLand';
import NavLoiterToAlt from './NavLoiterToAlt';
import NavReturnToLaunch from './NavReturnToLaunch';
import NavTakeoff from './NavTakeoff';
import NavWaypoint from './NavWaypoint';

import AccordionCaret from '@/components/ui/AccordionCaret';

const cx = classNames.bind(styles);

const Item = ({ label, data: missionItem }) => {
  const [isOpen, setIsOpen] = useState(false);

  const Icon = useMemo(() => {
    switch (missionItem.type) {
      case 'doChangeSpeed':
        return RiSpeedUpLine;
      case 'doSetCamTriggDist':
        return RiCameraLine;
      case 'doParachute':
        return RiVipDiamondLine;
      case 'navLand':
        return RiFlightLandLine;
      case 'navLoiterToAlt':
        return RiArrowUpDownLine;
      case 'navReturnToLaunch':
        return RiHome6Line;
      case 'navTakeoff':
        return RiFlightTakeoffLine;
      case 'navWaypoint':
        return RiMapPin2Line;
      default:
        return;
    }
  }, [missionItem]);

  const title = useMemo(() => {
    switch (missionItem.type) {
      case 'doChangeSpeed':
        return 'Change Speed';
      case 'doSetCamTriggDist':
        return 'Set Camera Trigger Distance';
      case 'doParachute':
        switch (missionItem.data.action) {
          case 0:
            return 'Parachute Disable';
          case 1:
            return 'Parachute Enable';
          case 2:
            return 'Parachute Release';
          default:
            return;
        }
      case 'navLand':
        return 'Land';
      case 'navLoiterToAlt':
        return 'Loiter To Altitude';
      case 'navReturnToLaunch':
        return 'Return To Launch';
      case 'navTakeoff':
        return 'Takeoff';
      case 'navWaypoint':
        return 'Waypoint';
      default:
        return;
    }
  }, [missionItem]);

  const toggle = (e) => {
    const isSelect = e.target.closest('select');
    if (isSelect) return;

    setIsOpen(!isOpen);
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')} onClick={toggle}>
        <div className={cx('left')}>
          <Icon size={18} color="white" />
          <div className={cx('label')}>{label}</div>
          <div className={cx('title')}>{title}</div>
        </div>
        <AccordionCaret up={isOpen} size={32} />
      </div>
      <div className={cx(['body', { open: isOpen }])}>
        <div className={cx('inner')}>
          {missionItem.type === 'doChangeSpeed' && <DoChangeSpeed data={missionItem} />}
          {missionItem.type === 'doSetCamTriggDist' && <DoSetCamTriggDist data={missionItem} />}
          {missionItem.type === 'doParachute' && <DoParachute data={missionItem} />}
          {missionItem.type === 'navLand' && <NavLand data={missionItem} />}
          {missionItem.type === 'navLoiterToAlt' && <NavLoiterToAlt data={missionItem} />}
          {missionItem.type === 'navReturnToLaunch' && <NavReturnToLaunch />}
          {missionItem.type === 'navTakeoff' && <NavTakeoff data={missionItem} />}
          {missionItem.type === 'navWaypoint' && <NavWaypoint data={missionItem} />}
        </div>
      </div>
    </div>
  );
};

export default Item;
