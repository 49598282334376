import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import actions from '@/actions';
import notificationTypes from '@/define/notificationTypes';
import { MessageContext } from '@/helpers/MessageProvider';
import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';

const Organizer = ({ data: robot }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const event = useSelector((state) => state.event[robot.id]);
  const heartbeat = useSelector((state) => state.telemetry[robot.id]?.m0);
  const paramValue = useSelector((state) => state.telemetry[robot.id]?.m22);
  const gpsRaw = useSelector((state) => state.telemetry[robot.id]?.m24);
  const attitude = useSelector((state) => state.telemetry[robot.id]?.m30);
  const globalPosition = useSelector((state) => state.telemetry[robot.id]?.m33);
  const missionCount = useSelector((state) => state.telemetry[robot.id]?.m44);
  const missionAck = useSelector((state) => state.telemetry[robot.id]?.m47);
  const missionItem = useSelector((state) => state.telemetry[robot.id]?.m73);
  const vfrHud = useSelector((state) => state.telemetry[robot.id]?.m74);
  const commandLong = useSelector((state) => state.telemetry[robot.id]?.m76);
  const commandAck = useSelector((state) => state.telemetry[robot.id]?.m77);
  const batteryStatus = useSelector((state) => state.telemetry[robot.id]?.m147);
  const cameraFeedback = useSelector((state) => state.telemetry[robot.id]?.m180);
  const magCalProgress = useSelector((state) => state.telemetry[robot.id]?.m191);
  const magCalReport = useSelector((state) => state.telemetry[robot.id]?.m192);
  const homePosition = useSelector((state) => state.telemetry[robot.id]?.m242);
  const statustext = useSelector((state) => state.telemetry[robot.id]?.m253);

  useWaitingRobotTimer(robot.id, [heartbeat]);

  useMountEffect(() => {
    const intervals = [];
    // prettier-ignore
    // eslint-disable-next-line no-lone-blocks
    {
      intervals.push(setInterval(() => publishCommand(robot, 'request/data_stream', [[1, 2, 1]]), 3000)); // MAV_DATA_STREAM_RAW_SENSORS
      intervals.push(setInterval(() => publishCommand(robot, 'request/data_stream', [[2, 2, 1]]), 3000)); // MAV_DATA_STREAM_EXTENDED_STATUS
      intervals.push(setInterval(() => publishCommand(robot, 'request/data_stream', [[3, 2, 1]]), 3000)); // MAV_DATA_STREAM_RC_CHANNELS
      intervals.push(setInterval(() => publishCommand(robot, 'request/data_stream', [[6, 3, 1]]), 3000)); // MAV_DATA_STREAM_POSITION
      intervals.push(setInterval(() => publishCommand(robot, 'request/data_stream', [[10, 10, 1]]), 3000)); // MAV_DATA_STREAM_EXTRA1
      intervals.push(setInterval(() => publishCommand(robot, 'request/data_stream', [[11, 10, 1]]), 3000)); // MAV_DATA_STREAM_EXTRA2
      intervals.push(setInterval(() => publishCommand(robot, 'request/data_stream', [[12, 3, 1]]), 3000)); // MAV_DATA_STREAM_EXTRA3
      intervals.push(setInterval(() => publishCommand(robot, 'request/heartbeat', [[]]), 1000));
      intervals.push(setInterval(() => publishCommand(robot, 'request/home_position', [[]]), 11000));
    }

    return () => {
      intervals.forEach(clearInterval);
    };
  });

  useEffect(() => {
    if (!globalPosition) return;

    let { lat, lon: lng } = globalPosition;
    lat /= 10000000;
    lng /= 10000000;

    EventEmitter.publish(`${robot.id}/event`, { lat, lng, event });
  }, [event]);

  useUpdateEffect(() => {
    const isArm = heartbeat.baseMode >= 128;
    const mode = heartbeat.customMode;

    EventEmitter.publish(`${robot.id}/telemetry/heartbeat`, { isArm, mode });
  }, [heartbeat]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/paramValue`, paramValue);
  }, [paramValue]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/gpsRaw`, gpsRaw);
  }, [gpsRaw]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/attitude`, attitude);
  }, [attitude]);

  useUpdateEffect(() => {
    let { lat, lon: lng, alt, relativeAlt: ralt, hdg } = globalPosition;
    lat /= 10000000;
    lng /= 10000000;
    alt /= 1000;
    ralt /= 1000;
    hdg /= 100;

    EventEmitter.publish(`${robot.id}/telemetry/globalPosition`, { lat, lng, alt, ralt, hdg });
  }, [globalPosition]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/missionCount`, missionCount);
  }, [missionCount]);

  useUpdateEffect(() => {
    const { type, missionType } = missionAck;
    if (type === 0 && missionType === 2) {
      publishCommand(robot, 'mission/request_list', [[]]);
    }
  }, [missionAck]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/missionItem`, missionItem);
  }, [missionItem]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/vfrHud`, vfrHud);
  }, [vfrHud]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/commandLong`, commandLong);
  }, [commandLong]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/commandAck`, commandAck);
  }, [commandAck]);

  useUpdateEffect(() => {
    const temperature = batteryStatus.temperature / 1000;
    const percentage = batteryStatus.batteryRemaining;
    const voltage = batteryStatus.voltages[0];

    EventEmitter.publish(`${robot.id}/telemetry/batteryStatus`, { temperature, percentage, voltage });
  }, [batteryStatus]);

  useUpdateEffect(() => {
    let { imgIdx, lat, lng } = cameraFeedback;
    lat /= 10000000;
    lng /= 10000000;

    EventEmitter.publish(`${robot.id}/telemetry/cameraFeedback`, { imgIdx, lat, lng });
  }, [cameraFeedback]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/magCalProgress`, magCalProgress);
  }, [magCalProgress]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/magCalReport`, magCalReport);
  }, [magCalReport]);

  useUpdateEffect(() => {
    let { latitude: lat, longitude: lng } = homePosition;
    lat /= 10000000;
    lng /= 10000000;

    EventEmitter.publish(`${robot.id}/telemetry/homePosition`, { lat, lng });
  }, [homePosition]);

  useUpdateEffect(() => {
    if (!statustext) return;

    switch (statustext.severity) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch(actions.notification.notify(robot.id, notificationTypes.FAILURE, statustext.text));
        break;
      case 6:
        dispatch(actions.notification.notify(robot.id, notificationTypes.SUCCESS, statustext.text));
        break;
      default:
        dispatch(actions.notification.notify(robot.id, notificationTypes.NORMAL, statustext.text));
        break;
    }
  }, [statustext]);

  return null;
};

export default Organizer;
