import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import React from 'react';

import Robot from './Robot';

import useFootprint from '@/hooks/useFootprint';

const MapMapper = ({ data: robot }) => {
  useFootprint(robot.id);

  return <Robot data={robot} />;
};

export default MapMapper;
