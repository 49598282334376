import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { registerCustomMessageMagicNumber } from 'node-mavlink';
import { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as sheco from './messages';

import actions from '@/actions';
import notificationTypes from '@/define/notificationTypes';
import { MessageContext } from '@/helpers/MessageProvider';
import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';

const Organizer = ({ data: robot }) => {
  const dispatch = useDispatch();
  const { publishCommand } = useContext(MessageContext);
  const heartbeat = useSelector((state) => state.telemetry[robot.id]?.m0);
  const gpsRaw = useSelector((state) => state.telemetry[robot.id]?.m24);
  const attitude = useSelector((state) => state.telemetry[robot.id]?.m30);
  const globalPosition = useSelector((state) => state.telemetry[robot.id]?.m33);
  const missionCount = useSelector((state) => state.telemetry[robot.id]?.m44);
  const missionAck = useSelector((state) => state.telemetry[robot.id]?.m47);
  const missionItem = useSelector((state) => state.telemetry[robot.id]?.m73);
  const vfrHud = useSelector((state) => state.telemetry[robot.id]?.m74);
  const batteryStatus = useSelector((state) => state.telemetry[robot.id]?.m147);
  const homePosition = useSelector((state) => state.telemetry[robot.id]?.m242);
  const statustext = useSelector((state) => state.telemetry[robot.id]?.m253);
  const usvData = useSelector((state) => state.telemetry[robot.id]?.m1101);

  useWaitingRobotTimer(robot.id, [heartbeat]);

  useMountEffect(() => {
    // MavLink custom messages 정의
    try {
      registerCustomMessageMagicNumber('1101', sheco.UsvData.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('1102', sheco.UsvEmergencyGcsData.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('1104', sheco.UsvOilactGcsData.MAGIC_NUMBER);
    } catch (e) {
      // TODO
    }
  });

  useMountEffect(() => {
    const intervals = [];
    intervals.push(setInterval(() => publishCommand(robot, 'request/heartbeat', [[]]), 1000));

    return () => {
      intervals.forEach(clearInterval);
    };
  });

  useUpdateEffect(() => {
    const isArm = heartbeat.baseMode >= 128;
    const mode = heartbeat.customMode;
    const status = heartbeat.systemStatus;

    EventEmitter.publish(`${robot.id}/telemetry/heartbeat`, { isArm, mode, status });
  }, [heartbeat]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/gpsRaw`, gpsRaw);
  }, [gpsRaw]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/attitude`, attitude);
  }, [attitude]);

  useUpdateEffect(() => {
    let { lat, lon: lng, alt, hdg } = globalPosition;
    lat /= 10000000;
    lng /= 10000000;
    alt /= 1000;
    hdg /= 100;

    EventEmitter.publish(`${robot.id}/telemetry/globalPosition`, { lat, lng, alt, hdg });
  }, [globalPosition]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/missionCount`, missionCount);
  }, [missionCount]);

  useUpdateEffect(() => {
    const { type, missionType } = missionAck;
    if (type === 0 && missionType === 2) {
      publishCommand(robot, 'mission/request_list', [[]]);
    }
  }, [missionAck]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/missionItem`, missionItem);
  }, [missionItem]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/vfrHud`, vfrHud);
  }, [vfrHud]);

  useUpdateEffect(() => {
    const temperature = batteryStatus.temperature / 1000;
    const percentage = batteryStatus.batteryRemaining;
    const voltage = batteryStatus.voltages[0];

    EventEmitter.publish(`${robot.id}/telemetry/batteryStatus`, { temperature, percentage, voltage });
  }, [batteryStatus]);

  useUpdateEffect(() => {
    let { latitude: lat, longitude: lng } = homePosition;
    lat /= 10000000;
    lng /= 10000000;

    EventEmitter.publish(`${robot.id}/telemetry/homePosition`, { lat, lng });
  }, [homePosition]);

  useUpdateEffect(() => {
    if (!statustext) return;

    switch (statustext.severity) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
        dispatch(actions.notification.notify(robot.id, notificationTypes.FAILURE, statustext.text));
        break;
      case 6:
        dispatch(actions.notification.notify(robot.id, notificationTypes.SUCCESS, statustext.text));
        break;
      default:
        dispatch(actions.notification.notify(robot.id, notificationTypes.NORMAL, statustext.text));
        break;
    }
  }, [statustext]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/usvData`, usvData);
  }, [usvData]);

  return null;
};

export default Organizer;
