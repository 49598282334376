import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { MdOutlineInfo, MdOutlineCheckCircleOutline, MdOutlineDangerous } from 'react-icons/md';
import { useSelector } from 'react-redux';

import styles from './Notifications.module.scss';

import EmptyList from '@/components/ui/EmptyList';
import notificationTypes from '@/define/notificationTypes';

const cx = classNames.bind(styles);

const HOVER_ROW = {
  index: -1,
  isOverflow: false,
};

const Notifications = ({ robotId }) => {
  const notifications = useSelector((state) => state.notification[robotId]);
  const [hoverRow, setHoverRow] = useState(HOVER_ROW);

  const updateHoverRow = (e, index) => {
    const textElement = e.currentTarget.querySelector('[data-text]');
    const isOverflow = textElement.clientWidth < textElement.scrollWidth;
    setHoverRow({ index, isOverflow });
  };

  return (
    <div className={cx('container')}>
      {(!notifications || notifications.length === 0) && (
        <div className={cx('empty')}>
          <EmptyList message="No items" />
        </div>
      )}
      {notifications?.map((notification, index) => (
        <div
          key={index}
          className={cx([
            'row',
            {
              success: notification.type === notificationTypes.SUCCESS,
              failure: notification.type === notificationTypes.FAILURE,
            },
          ])}
          onMouseEnter={(e) => updateHoverRow(e, index)}
          onMouseLeave={() => setHoverRow(HOVER_ROW)}>
          <div>
            {notification.type === notificationTypes.NORMAL && <MdOutlineInfo size={16} color="white" />}
            {notification.type === notificationTypes.SUCCESS && <MdOutlineCheckCircleOutline size={16} color="white" />}
            {notification.type === notificationTypes.FAILURE && <MdOutlineDangerous size={16} color="white" />}
          </div>
          <div className={cx('textWrapper')}>
            <div
              data-text
              title={notification.text}
              className={cx(['text', { marquee: index === hoverRow.index && hoverRow.isOverflow }])}>
              {notification.text}
            </div>
          </div>
          <div className={cx('time')}>{notification.time}</div>
        </div>
      ))}
    </div>
  );
};

export default Notifications;
