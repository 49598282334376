import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class GimbalStatus extends MavLinkData {
  constructor() {
    super();
    this.pitchAngle = 0;
    this.relYawAngle = 0;
    this.absYawAngle = 0;
    this.gimbalModelNo = 0;
    this.gimbalMode = 0;
    this.headingMode = 0;
    this.reserved = 0;
  }
}

GimbalStatus.MSG_ID = 1003;
GimbalStatus.MSG_NAME = 'GIMBAL_STATUS';
GimbalStatus.PAYLOAD_LENGTH = 17;
GimbalStatus.MAGIC_NUMBER = 119;
GimbalStatus.FIELDS = [
  new MavLinkPacketField('pitchAngle', 'pitchAngle', 0, false, 4, 'int32_t', ''),
  new MavLinkPacketField('relYawAngle', 'relYawAngle', 4, false, 4, 'int32_t', ''),
  new MavLinkPacketField('absYawAngle', 'absYawAngle', 8, false, 4, 'int32_t', ''),
  new MavLinkPacketField('gimbalModelNo', 'gimbalModelNo', 12, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('gimbalMode', 'gimbalMode', 13, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('headingMode', 'headingMode', 14, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('reserved', 'reserved', 15, false, 1, 'uint8_t', ''),
];

export default GimbalStatus;
