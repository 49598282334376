import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class CameraCommand extends MavLinkData {
  constructor() {
    super();
    this.serviceId = 0;
    this.irZoom = 0;
    this.mode = 0;
    this.irColorMode = 0;
    this.recoding = 0;
    this.capture = 0;
    this.stabilize = 0;
  }
}

CameraCommand.MSG_ID = 4901;
CameraCommand.MSG_NAME = 'CAMERA_COMMAND';
CameraCommand.PAYLOAD_LENGTH = 9;
CameraCommand.MAGIC_NUMBER = 116;
CameraCommand.FIELDS = [
  new MavLinkPacketField('service_id', 'serviceId', 0, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('ir_zoom', 'irZoom', 2, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('mode', 'mode', 4, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('ir_color_mode', 'irColorMode', 5, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('recoding', 'recoding', 6, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('capture', 'capture', 7, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('stabilize', 'stabilize', 8, false, 1, 'uint8_t', ''),
];

export default CameraCommand;
