import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState, useMemo } from 'react';
import {
  RiFlightTakeoffLine,
  RiFlightLandLine,
  RiArrowUpDownLine,
  RiHome6Line,
  RiMapPin2Line,
  RiCloseLine,
} from 'react-icons/ri';
import { useDispatch } from 'react-redux';

import styles from './index.module.scss';
import Land from './Land';
import LoiterAltitude from './LoiterAltitude';
import ReturnToLaunch from './ReturnToLaunch';
import Survey from './Survey';
import Takeoff from './Takeoff';
import Waypoint from './Waypoint';

import actions from '@/actions';
import AccordionCaret from '@/components/ui/AccordionCaret';
import OlMap from '@/helpers/OlMap';

const cx = classNames.bind(styles);

const MissionItem = ({ index, data: missionItem }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const map = OlMap.getMap();

  // Waypoint, Land, Loiter (Altitude) 경우 유형 변경 가능
  const isChangeableType = useMemo(() => {
    return ['navLand', 'navLoiterToAlt', 'navWaypoint'].includes(missionItem.type);
  }, [missionItem]);

  const Icon = useMemo(() => {
    switch (missionItem.type) {
      case 'navLand':
        return RiFlightLandLine;
      case 'navLoiterToAlt':
        return RiArrowUpDownLine;
      case 'navReturnToLaunch':
        return RiHome6Line;
      case 'navTakeoff':
        return RiFlightTakeoffLine;
      case 'navWaypoint':
        return RiMapPin2Line;
      case 'cusSurvey':
        return RiMapPin2Line;
      default:
        return;
    }
  }, [missionItem]);

  useMountEffect(() => {
    const clickMarker = () => {
      setIsOpen((prevState) => !prevState);
    };

    map.on(`mission/${missionItem.id}/click`, clickMarker);

    return () => {
      map.un(`mission/${missionItem.id}/click`, clickMarker);
    };
  });

  const changeType = (e) => {
    dispatch(actions.editor.changeMissionItemType(index, e.target.value));
  };

  const toggle = (e) => {
    const isSelect = e.target.closest('select');
    if (isSelect) return;

    setIsOpen(!isOpen);
  };

  const remove = () => {
    dispatch(actions.editor.removeMissionItem(index));
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header')} onClick={toggle}>
        <div className={cx('left')}>
          <Icon size={18} color="white" />
          <select value={missionItem.type} disabled={!isChangeableType} className={cx('type')} onChange={changeType}>
            <option value="navLand">Land</option>
            <option value="navLoiterToAlt">Loiter (Altitude)</option>
            <option value="navReturnToLaunch" hidden>
              Return To Launch
            </option>
            <option value="navTakeoff" hidden>
              Takeoff
            </option>
            <option value="navWaypoint">Waypoint</option>
            <option value="cusSurvey" hidden>
              Survey
            </option>
          </select>
        </div>
        <div className={cx('right')}>
          {missionItem.type !== 'navTakeoff' && (
            <button type="button" className={cx('button')} onClick={remove}>
              <RiCloseLine size={14} color="white" />
            </button>
          )}
          <AccordionCaret up={isOpen} size={32} />
        </div>
      </div>
      <div className={cx(['body', { open: isOpen }])}>
        <div className={cx('inner')}>
          {missionItem.type === 'navLand' && <Land index={index} data={missionItem} />}
          {missionItem.type === 'navLoiterToAlt' && <LoiterAltitude index={index} data={missionItem} />}
          {missionItem.type === 'navReturnToLaunch' && <ReturnToLaunch />}
          {missionItem.type === 'navTakeoff' && <Takeoff index={index} data={missionItem} />}
          {missionItem.type === 'navWaypoint' && <Waypoint index={index} data={missionItem} />}
          {missionItem.type === 'cusSurvey' && <Survey index={index} data={missionItem} />}
        </div>
      </div>
    </div>
  );
};

export default MissionItem;
