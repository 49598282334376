import classNames from 'classnames/bind';
import React, { useState } from 'react';

import Calibration from './01_Calibration';
import Paramaters from './02_Paramaters';
import styles from './index.module.scss';

import ModalWrapper from '@/components/ui/Modal';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Modal = ({ robot }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const selectTab = (e) => {
    setActiveTabIndex(Number(e.target.dataset.index));
  };

  const close = () => {
    modal.hide();
  };

  return (
    <ModalWrapper>
      <div className={cx('container')}>
        <div className={cx('tabs')}>
          <div data-index={0} className={cx(['tab', { active: activeTabIndex === 0 }])} onClick={selectTab}>
            Calibration
          </div>
          <div data-index={1} className={cx(['tab', { active: activeTabIndex === 1 }])} onClick={selectTab}>
            Parameters
          </div>
        </div>
        <div className={cx(['pane', { active: activeTabIndex === 0 }])}>
          <Calibration robot={robot} />
        </div>
        <div className={cx(['pane', { active: activeTabIndex === 1 }])}>
          <Paramaters robot={robot} />
        </div>
      </div>
      <div className={cx('bottom')}>
        <button onClick={close} type="button" className={cx('button')}>
          Close
        </button>
      </div>
    </ModalWrapper>
  );
};

export default Modal;
