import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import { asArray } from 'ol/color';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import VectorLayer from 'ol/layer/Vector';
import { fromLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill, Text } from 'ol/style';
import React, { useState, useRef, useMemo } from 'react';
import { RiCheckboxBlankLine, RiCheckboxFill, RiMapPin5Line } from 'react-icons/ri';

import styles from './ZonesSelector.module.scss';
import AccordionCaret from '../AccordionCaret';
import Bar from '../Bar';

import geoZones from '@/define/geoZones';
import API from '@/helpers/API';
import OlMap from '@/helpers/OlMap';
import { ToastService as toast } from '@/libs/Toast';

const cx = classNames.bind(styles);

const Zones = () => {
  const [showPanel, setShowPanel] = useState(false);
  const [zones, setZones] = useState();
  const polygons = useRef([]);
  const map = OlMap.getMap();

  const count = useMemo(() => {
    return zones?.filter((zone) => zone.on).length ?? 0;
  }, [zones]);

  useMountEffect(() => {
    const promises = Object.keys(geoZones).map((name) =>
      API.get(`/proxy/vworld?data=LT_C_AIS${name}`).then(({ data }) => ({
        name,
        ...geoZones[name],
        features: data.response?.result.featureCollection.features ?? null,
      }))
    );

    Promise.all(promises).then((results) => {
      const nextZones = results.map((result) => ({ ...result, on: false }));
      setZones(nextZones);
    });
  });

  const togglePanel = () => {
    setShowPanel(!showPanel);
  };

  const toggleZone = (e, zone) => {
    e.stopPropagation();

    if (!zone.on) {
      if (zone.features === null) {
        toast.error('Failed to load the zone.');
        return;
      }

      showZone(zone);
    } else {
      hideZone(zone);
    }

    zone.on = !zone.on;
    setZones([...zones]);
  };

  const showZone = ({ color, features, title }) => {
    features.forEach(({ id, geometry }) => {
      // 색상 RGB 값 분리
      const [r, g, b] = asArray(color);

      const coordinates = geometry.coordinates[0][0].map((coord) => fromLonLat(coord));
      // TODO: MultiPolygon 활용
      const feature = new Feature(new Polygon([coordinates]));
      feature.setId(id);
      const source = new VectorSource({ features: [feature], wrapX: false });
      const layer = new VectorLayer({
        source,
        style: new Style({
          stroke: new Stroke({ color, width: 2 }),
          fill: new Fill({ color: [r, g, b, 0.2] }),
          text: new Text({
            text: title,
            font: 'bold 12px "Noto Sans KR", sans-serif',
            fill: new Fill({ color: [r, g, b, 0.8] }),
            stroke: new Stroke({ color: [255, 255, 255, 0.8], width: 3 }),
          }),
        }),
      });
      map.addLayer(layer);
      polygons.current.push(layer);
    });
  };

  const hideZone = ({ features }) => {
    const featureIds = features.map(({ id }) => id);
    const filtered = polygons.current.filter((polygon) =>
      featureIds.includes(polygon.getSource().getFeatures()[0].getId())
    );

    filtered.forEach((polygon) => {
      map.removeLayer(polygon);
      const index = polygons.current.indexOf(polygon);
      polygons.current.splice(index, 1);
    });
  };

  return (
    <div className={cx('container')}>
      <Bar />
      <div className={cx('selector')} onClick={togglePanel}>
        <div className={cx('left')}>
          <RiMapPin5Line size={16} />
          <div className={cx('label')}>Zones {count > 0 && `(${count})`}</div>
        </div>
        <AccordionCaret up={showPanel} size={32} />
        <div className={cx(['panel', { show: showPanel }])}>
          <div className={cx('menus')}>
            {zones?.map((zone, index) => (
              <div key={index} onClick={(e) => toggleZone(e, zone)} className={cx('menu')}>
                {zone.on && <RiCheckboxFill size={14} color={zone.color} />}
                {!zone.on && <RiCheckboxBlankLine size={14} color="white" />}
                <div className={cx(['menuText', { active: zone.on }])}>{zone.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Zones;
