import * as turf from '@turf/turf';
import axios from 'axios';
import * as mgrs from 'mgrs';

import CoordUtils from './CoordUtils';

import { BING_API_KEY, VIRTUALEARTH_BASE_URL } from '@/config';

// 거리(m) 획득
export const getDistance = (from, to) => {
  from = CoordUtils.arrayFromObject(from);
  to = CoordUtils.arrayFromObject(to);

  return turf.distance(from, to) * 1000;
};

// 경로 총 거리(m) 획득
export const getDistanceAlongPath = (path) => {
  if (path.length < 2) return 0;

  const line = turf.lineString(path.map(({ lat, lng }) => [lng, lat]));
  return turf.length(line) * 1000;
};

// 위치 고도 획득
export const getElevation = async (position) => {
  const { lat, lng } = getValidCoords(position);

  const result = await axios.get(`${VIRTUALEARTH_BASE_URL}/Elevation/List`, {
    params: {
      key: BING_API_KEY,
      points: `${lat},${lng}`,
    },
  });
  // Elevation is always an integer
  return result.data.resourceSets[0].resources[0].elevations[0];
};

// 경로 고도 획득
export const getElevationAlongPath = async (path) => {
  const points = path
    .map(getValidCoords)
    .map(({ lat, lng }) => `${lat},${lng}`)
    .join(',');
  const distance = getDistanceAlongPath(path);
  const samples = Math.max(2, Math.min(Math.ceil(distance / 2), 1024));

  const result = await axios.get(`${VIRTUALEARTH_BASE_URL}/Elevation/Polyline`, {
    params: {
      key: BING_API_KEY,
      points,
      samples,
    },
  });

  return result.data.resourceSets[0].resources[0].elevations;
};

// 좌표 → MGRS
export const getMGRS = ({ lat, lng }) => {
  try {
    return mgrs.forward([lng, lat]);
  } catch (error) {
    console.error(error.message);
    return 'OUT OF BOUNDS';
  }
};

// MGRS → 좌표
export const getFromMGRS = (geocode) => {
  try {
    return CoordUtils.objectFromArray(mgrs.toPoint(geocode));
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

// 지오코딩 (주소 → 좌표)
export const geocoding = async ({ address }) => {
  try {
    const result = await axios.get(`${VIRTUALEARTH_BASE_URL}/Locations`, {
      params: {
        key: BING_API_KEY,
        query: encodeURIComponent(address),
      },
    });
    const [lat, lng] = result.data.resourceSets[0].resources[0].point.coordinates;

    return { lat, lng };
  } catch {
    throw new Error('There are no matching addresses.');
  }
};

const getValidCoords = ({ lat, lng }) => {
  lat = Math.max(-85, Math.min(lat, 85));
  lng = Math.max(-180, Math.min(lng, 180));

  return { lat, lng };
};
