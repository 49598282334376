import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useState, useContext } from 'react';
import { Joystick } from 'react-joystick-component';

import styles from './common.module.scss';

import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Action = ({ data: robots }) => {
  const { publishCommand } = useContext(MessageContext);
  const [isVirtualJoystick, setIsVirtualJoystick] = useState(true);
  const [joystickPosition, setJoystickPosition] = useState({ x: 0, y: 0 });

  useMountEffect(() => {
    robots.forEach((robot) => {
      publishCommand(robot, 'action/manual', [[]]);
      EventEmitter.publish(`${robot.id}/event/resetGoto`);
    });

    return () => {
      robots.forEach((robot) => {
        publishCommand(robot, 'action/hold', [[]]);
      });
    };
  });

  useUpdateEffect(() => {
    if (isVirtualJoystick) return;

    let frameId;
    const handleJoystick = () => {
      const joystick = navigator.getGamepads()[0];
      if (joystick) {
        moveJoystick({
          x: joystick.axes[0],
          y: -joystick.axes[1],
        });
      }

      frameId = window.requestAnimationFrame(handleJoystick);
    };

    handleJoystick();

    return () => {
      window.cancelAnimationFrame(frameId);
      stopJoystick();
    };
  }, [isVirtualJoystick]);

  const moveJoystick = ({ x, y }) => {
    robots.forEach((robot) => {
      EventEmitter.publish(`${robot.id}/set/manualControl`, {
        y: y > 0 ? parseInt(x * 1000) : -parseInt(x * 1000),
        z: parseInt(y * 1000),
      });
    });

    if (!isVirtualJoystick) {
      setJoystickPosition({ x, y });
    }
  };

  const stopJoystick = () => {
    robots.forEach((robot) => {
      EventEmitter.publish(`${robot.id}/set/manualControl`, {
        y: 0,
        z: 0,
      });
    });

    if (!isVirtualJoystick) {
      setJoystickPosition({ x: 0, y: 0 });
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('switch')}>
        <div className={cx(['bar', { left: !isVirtualJoystick }])} />
        <div className={cx('buttons')}>
          <div className={cx('button')} onClick={() => setIsVirtualJoystick(false)}>
            Real
          </div>
          <div className={cx('button')} onClick={() => setIsVirtualJoystick(true)}>
            Virtual
          </div>
        </div>
      </div>
      <Joystick
        size={120}
        stickSize={64}
        sticky={false}
        stickImage={require('./joystick-red.png')}
        baseImage={require('./joystick-base.png')}
        move={moveJoystick}
        stop={stopJoystick}
        pos={joystickPosition}
        disabled={!isVirtualJoystick}
      />
    </div>
  );
};

export default Action;
