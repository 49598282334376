import React from 'react';

import Body from './Body';
import Mode from './Mode';
import Status from './Status';

import { ControlBar, Frame } from '@/components/ui/Dashboard';

const Dashboard = ({ data: robot }) => {
  return (
    <Frame robot={robot} status={<Status robotId={robot.id} />} mode={<Mode robotId={robot.id} />}>
      <Body robotId={robot.id} />
      <ControlBar robot={robot} settings />
    </Frame>
  );
};

export default Dashboard;
