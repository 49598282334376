import * as turf from '@turf/turf';
import Collection from 'ol/Collection';
import Feature from 'ol/Feature';
import Polygon from 'ol/geom/Polygon';
import { Modify, Translate } from 'ol/interaction';
import { Vector as VectorLayer } from 'ol/layer';
import { fromLonLat, toLonLat } from 'ol/proj';
import { Vector as VectorSource } from 'ol/source';
import { Style, Stroke, Fill } from 'ol/style';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import actions from '@/actions';
import OlMap from '@/helpers/OlMap';
import { ToastService as toast } from '@/libs/Toast';
import CoordUtils from '@/utils/CoordUtils';
import { getPositions } from '@/utils/SurveyCalculator';

const Group = ({ index, data }) => {
  const dispatch = useDispatch();
  const map = OlMap.getMap();

  const originCoords = useMemo(() => {
    return data.boundary.map(({ lat, lng }) => fromLonLat([lng, lat]));
  }, [data.boundary]);

  useEffect(() => {
    const feature = new Feature(new Polygon([originCoords]));
    const source = new VectorSource({ features: [feature], wrapX: false });
    const layer = new VectorLayer({
      source,
      style: new Style({
        stroke: new Stroke({ color: 'black', width: 4 }),
        fill: new Fill({ color: [0, 0, 0, 0.3] }),
      }),
    });
    map.addLayer(layer);

    const translate = new Translate({ features: new Collection([feature]) });
    translate.on('translateend', editShape);
    map.addInteraction(translate);

    const modify = new Modify({ source });
    modify.on('modifyend', editShape);
    map.addInteraction(modify);

    return () => {
      source.clear();
      map.removeLayer(layer);
    };
  }, [originCoords]);

  const editShape = (e) => {
    const coords = e.features.item(0).getGeometry().getCoordinates()[0];
    const polygon = turf.polygon([coords]);
    const selfIntersections = turf.kinks(polygon).features;

    // 자기교차점 존재 시
    if (selfIntersections.length > 0) {
      e.features.item(0).getGeometry().setCoordinates([originCoords]);
      toast.error('The edited area cannot have self-intersections. Please retry.');
      return;
    }

    const boundary = coords.map((coord) => {
      return CoordUtils.objectFromArray(toLonLat(coord));
    });
    const options = {
      gap: data.gap,
      turnaround: data.turnaround,
      rotate: data.rotate,
      camera: data.camera,
    };
    const positions = getPositions(boundary, options);
    // 경로점 미존재 시
    if (positions.length === 0) {
      e.features.item(0).getGeometry().setCoordinates([originCoords]);
      toast.error('The edited area is too small.');
      return;
    }

    dispatch(actions.editor.editSurvey(index, { boundary, positions }));
  };

  return null;
};

export default Group;
