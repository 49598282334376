import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import React, { useRef } from 'react';

import Robot from './Robot';

import OlMap from '@/helpers/OlMap';
import useCameraFeedback from '@/hooks/useCameraFeedback';
import useFootprint from '@/hooks/useFootprint';
import useIoTHubEvent from '@/hooks/useIoTHubEvent';
import useMission, { ArduPilot } from '@/hooks/useMission';
import EventEmitter from '@/libs/EventEmitter';

const MapMapper = ({ data: robot }) => {
  const robotColor = useRef(robot.color);

  useCameraFeedback(robot.id);
  useFootprint(robot.id);
  useIoTHubEvent(robot);
  useMission(ArduPilot, robot);

  useMountEffect(() => {
    const subscribeTokens = [];
    subscribeTokens.push(
      EventEmitter.subscribe(`${robot.id}/telemetry/globalPosition`, ({ lat, lng }) => {
        OlMap.addHomePath(robot.id, { lat, lng });
        OlMap.addGotoPath(robot.id, { lat, lng }, robotColor.current);
      })
    );
    subscribeTokens.push(
      EventEmitter.subscribe(`${robot.id}/telemetry/homePosition`, ({ lat, lng }) => {
        OlMap.addHomeMarker(robot.id, { lat, lng }, robotColor.current);
      })
    );
    subscribeTokens.push(
      EventEmitter.subscribe(`${robot.id}/event/resetGoto`, () => {
        OlMap.removeGoto(robot.id);
      })
    );

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));

      OlMap.removeHome(robot.id);
      OlMap.removeGoto(robot.id);
    };
  });

  useUpdateEffect(() => {
    robotColor.current = robot.color;
    OlMap.changeColor(robot.id, robotColor.current);
  }, [robot.color]);

  return <Robot data={robot} />;
};

export default MapMapper;
