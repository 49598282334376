import { asArray } from 'ol/color';
import { defaults } from 'ol/control/defaults';
import { pointerMove } from 'ol/events/condition';
import { boundingExtent, containsCoordinate } from 'ol/extent';
import Feature from 'ol/Feature';
import { LineString, Point } from 'ol/geom';
import { Select } from 'ol/interaction';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import { toLonLat, fromLonLat } from 'ol/proj';
import { Vector as VectorSource, XYZ } from 'ol/source';
import BingMaps from 'ol/source/BingMaps';
import { Style, Stroke, Fill, Circle } from 'ol/style';
import View from 'ol/View';
import { createRoot } from 'react-dom/client';

import Waypoint from '@/components/ui/map/Waypoint';
import {
  BING_API_KEY,
  VWORLD_API_KEY,
  OL_DEFAULT_MAP_ID,
  OL_MAX_ZOOM,
  OL_RESTRICTED_BOUNDS,
  OL_VWORLD_BOUNDS,
} from '@/config';
import MAPS from '@/define/olMaps';
import CoordUtils from '@/utils/CoordUtils';

let map;
let currentMapId = OL_DEFAULT_MAP_ID;

class OlMap {
  #robots;
  #homes;
  #gotos;
  #missions;
  #shoots;
  #footprints;

  constructor() {
    this.#robots = {};
    this.#homes = {};
    this.#gotos = {};
    this.#missions = {};
    this.#shoots = {};
    this.#footprints = {};
  }

  init(target, options) {
    const view = new View({
      center: fromLonLat(CoordUtils.arrayFromObject(options.center)),
      zoom: options.zoom,
      maxZoom: OL_MAX_ZOOM,
      extent: this.#getExtent(),
    });

    // 지도 선언
    map = new Map({
      target,
      view,
      layers: this.#createLayers(currentMapId, 0),
      controls: defaults({ zoom: false, attribution: false, rotate: false }),
    });

    return map;
  }

  #getExtent() {
    // Restricting Map Bounds
    if (process.env.REACT_APP_USE_MAP_RESTRICTION === 'true') {
      return [
        ...fromLonLat([OL_RESTRICTED_BOUNDS.west, OL_RESTRICTED_BOUNDS.south]),
        ...fromLonLat([OL_RESTRICTED_BOUNDS.east, OL_RESTRICTED_BOUNDS.north]),
      ];
    }
  }

  #createLayers(mapId, typeIndex) {
    const type = MAPS[mapId].types[typeIndex];

    // Bing Map
    if (mapId === 'BING') {
      return [
        new TileLayer({
          source: new BingMaps({ key: BING_API_KEY, imagerySet: type.key }),
        }),
      ];
    }
    // Google Map
    else if (mapId === 'GOOGLE') {
      return [
        new TileLayer({
          source: new XYZ({ url: `http://mt0.google.com/vt/lyrs=${type.key}&hl=en&x={x}&y={y}&z={z}` }),
        }),
      ];
    }
    // VWorld Map
    else if (mapId === 'VWORLD') {
      const isSatellite = type.key === 'Satellite';
      const extension = isSatellite ? 'jpeg' : 'png';

      const layers = [
        new TileLayer({
          source: new XYZ({
            url: `http://api.vworld.kr/req/wmts/1.0.0/${VWORLD_API_KEY}/${type.key}/{z}/{y}/{x}.${extension}`,
          }),
        }),
      ];

      // 위성사진인 경우 Hybrid 레이어 추가
      if (isSatellite) {
        layers.push(
          new TileLayer({
            source: new XYZ({ url: `http://api.vworld.kr/req/wmts/1.0.0/${VWORLD_API_KEY}/Hybrid/{z}/{y}/{x}.png` }),
          })
        );
      }
      return layers;
    }
  }

  changeMap(mapId, typeIndex) {
    // 변경된 지도 아이디 갱신
    currentMapId = mapId;

    const layers = this.#createLayers(mapId, typeIndex);
    const vectorLayers = map
      .getLayers()
      .getArray()
      .filter((layer) => layer instanceof VectorLayer);

    map.setLayers([...layers, ...vectorLayers]);

    const options = {
      center: map.getView().getCenter(),
      zoom: map.getView().getZoom(),
      maxZoom: OL_MAX_ZOOM,
      extent: this.#getExtent(),
    };
    // 변경된 지도 VWorld 인 경우
    if (mapId === 'VWORLD') {
      options.extent = [
        ...fromLonLat([OL_VWORLD_BOUNDS.west, OL_VWORLD_BOUNDS.south]),
        ...fromLonLat([OL_VWORLD_BOUNDS.east, OL_VWORLD_BOUNDS.north]),
      ];
    }

    const view = new View(options);
    view.on('change:center', map.getView().listeners_['change:center'][0]);
    map.setView(view);
  }

  getMap() {
    return map;
  }

  getCenter() {
    const center = map.getView().getCenter();
    return CoordUtils.objectFromArray(toLonLat(center));
  }

  fitBounds(positions) {
    // 위치 갯수 1개인 경우
    if (positions.length === 1) {
      map.getView().setCenter(fromLonLat(positions[0]));
      map.getView().setZoom(15);
    }
    // 위치 갯수 1개 초과인 경우
    else if (positions.length > 1) {
      const coordinates = positions.map((position) => fromLonLat(position));
      const extent = boundingExtent(coordinates);
      map.getView().fit(extent, { padding: [120, 120, 120, 120] });
    }
  }

  fitBoundsAuto() {
    // 로봇
    const robots = Object.values(this.#robots).map((robot) => toLonLat(robot.values_.position));
    // 미션
    const missions = Object.values(this.#missions).flatMap((mission) =>
      mission.markers.map((marker) => toLonLat(marker.values_.position))
    );
    let positions = [...robots, ...missions];

    // 현재 지도 VWorld 인 경우
    if (this.checkCurrentVWorld()) {
      // VWorld 영역 내 위치
      positions = positions.filter((position) => this.checkInsideOfVWorld(position));
    }
    this.fitBounds(positions);
  }

  createOverlay(options) {
    options.element.addEventListener('wheel', (e) => {
      map.getViewport().dispatchEvent(new WheelEvent(e.type, e));
    });

    return new Overlay(options);
  }

  changeColor(robotId, color) {
    // Home maker
    if (this.#homes[robotId]) {
      const position = this.#homes[robotId].position;
      this.removeHome(robotId);
      this.addHomeMarker(robotId, position, color);
    }

    // Goto maker and path
    if (this.#gotos[robotId]) {
      const position = this.#gotos[robotId].position;
      this.removeGoto(robotId);
      this.addGotoMarker(robotId, position, color);
      this.addGotoPath(robotId, position, color);
    }
  }

  //#region VWorld
  checkCurrentVWorld() {
    return currentMapId === 'VWORLD';
  }

  checkInsideOfVWorld(position) {
    const extent = [OL_VWORLD_BOUNDS.west, OL_VWORLD_BOUNDS.south, OL_VWORLD_BOUNDS.east, OL_VWORLD_BOUNDS.north];
    return containsCoordinate(extent, position);
  }
  //#endregion

  //#region Robot
  addRobot(robotId, element, { lat, lng }) {
    const position = fromLonLat([lng, lat]);

    // 기존 로봇 마커
    if (this.#robots[robotId]) {
      this.#robots[robotId].setPosition(position);
    }
    // 신규 로봇 마커
    else {
      this.#robots[robotId] = this.createOverlay({
        element,
        position,
        positioning: 'top-center',
        offset: [0, -24],
        insertFirst: false,
      });
      map.addOverlay(this.#robots[robotId]);
    }
  }

  removeRobot(robotId) {
    if (!this.#robots[robotId]) return;

    map.removeOverlay(this.#robots[robotId]);
    delete this.#robots[robotId];
  }
  //#endregion

  //#region Home
  addHomeMarker(robotId, position, color) {
    // 기존 Home Marker
    if (this.#homes[robotId]) {
      this.#homes[robotId].position = position;
      this.#homes[robotId].marker.setPosition(fromLonLat([position.lng, position.lat]));
    }
    // 신규 Home Marker
    else {
      this.#homes[robotId] = {
        position,
        marker: this.#drawMarker(position, 'H', color),
      };
    }
  }

  addHomePath(robotId, position) {
    // Home 미정의 시
    if (!this.#homes[robotId]) return;

    // 두 점 간 직선 정의
    const from = this.#homes[robotId].marker.getPosition();
    const to = fromLonLat([position.lng, position.lat]);
    const path = [from, to];

    // 기존 경로
    if (this.#homes[robotId].path) {
      const feature = this.#homes[robotId].path.getSource().getFeatures()[0];
      feature.getGeometry().setCoordinates(path);
    }
    // 신규 경로
    else {
      const feature = new Feature(new LineString(path));
      const source = new VectorSource({ features: [feature] });
      const layer = new VectorLayer({
        source,
        style: new Style({
          stroke: new Stroke({
            color: [255, 255, 255, 0.5],
            width: 2,
            lineDash: [4, 8], // [길이, 간격]
          }),
        }),
      });

      map.addLayer(layer);
      this.#homes[robotId].path = layer;
    }
  }

  removeHome(robotId) {
    if (!this.#homes[robotId]) return;

    map.removeOverlay(this.#homes[robotId].marker);
    map.removeLayer(this.#homes[robotId].path);
    delete this.#homes[robotId];
  }
  //#endregion

  //#region Goto
  addGotoMarker(robotId, position, color) {
    // 기존 Goto Marker
    if (this.#gotos[robotId]) {
      this.#gotos[robotId].position = position;
      this.#gotos[robotId].marker.setPosition(fromLonLat([position.lng, position.lat]));
    }
    // 신규 Goto Marker
    else {
      this.#gotos[robotId] = {
        position,
        marker: this.#drawMarker(position, 'G', color),
      };
    }
  }

  addGotoPath(robotId, position, color) {
    // Goto 미정의 시
    if (!this.#gotos[robotId]) return;

    // 두 점 간 직선 정의
    const from = fromLonLat([position.lng, position.lat]);
    const to = this.#gotos[robotId].marker.getPosition();
    const path = [from, to];

    // 기존 경로
    if (this.#gotos[robotId].path) {
      const feature = this.#gotos[robotId].path.getSource().getFeatures()[0];
      feature.getGeometry().setCoordinates(path);
    }
    // 신규 경로
    else {
      this.#gotos[robotId].path = this.#drawPath(robotId, path, color);
    }
  }

  removeGoto(robotId) {
    if (!this.#gotos[robotId]) return;

    map.removeOverlay(this.#gotos[robotId].marker);
    map.removeLayer(this.#gotos[robotId].path);
    delete this.#gotos[robotId];
  }
  //#endregion

  //#region Mission
  addMission(robotId, markers, color) {
    this.#missions[robotId] = {
      markers: this.#drawMissionMarkers(markers, color),
      paths: this.#drawMissionPaths(robotId, markers, color),
    };
    return this.#missions[robotId];
  }

  removeMission(robotId) {
    if (!this.#missions[robotId]) return;

    this.#missions[robotId].paths.forEach((path) => map.removeLayer(path));
    this.#missions[robotId].markers.forEach((marker) => map.removeOverlay(marker));
    delete this.#missions[robotId];
  }
  //#endregion

  //#region Shoot
  addShoot(robotId, { lat, lng }) {
    const point = new Point(fromLonLat([lng, lat]));
    const feature = new Feature(point);

    // 후속 촬영 시
    if (this.#shoots[robotId]) {
      this.#shoots[robotId].getSource().addFeature(feature);
    }
    // 최초 촬영 시
    else {
      const source = new VectorSource({ features: [feature], wrapX: false });
      const layer = new VectorLayer({
        source,
        declutter: true,
        style: new Style({
          image: new Circle({
            radius: 6,
            fill: new Fill({ color: 'rgba(0, 0, 0, 0.6)' }),
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.4)',
              width: 8,
            }),
          }),
        }),
      });

      map.addLayer(layer);
      this.#shoots[robotId] = layer;
    }
  }

  removeShoot(robotId) {
    if (!this.#shoots[robotId]) return;

    map.removeLayer(this.#shoots[robotId]);
    delete this.#shoots[robotId];
  }
  //#endregion

  //#region Footprint
  addFootprint(robotId, { lat, lng }) {
    const position = fromLonLat([lng, lat]);

    // 기존 이동 경로
    if (this.#footprints[robotId]) {
      const feature = this.#footprints[robotId].getSource().getFeatures()[0];
      feature.getGeometry().appendCoordinate(position);
    }
    // 신규 이동 경로
    else {
      const feature = new Feature(new LineString([position]));
      const source = new VectorSource({ features: [feature] });
      const layer = new VectorLayer({
        source,
        style: new Style({
          stroke: new Stroke({
            color: [255, 255, 255, 0.5],
            width: 4,
          }),
        }),
      });

      map.addLayer(layer);
      this.#footprints[robotId] = layer;

      // Mouse over 시 강조
      const handleHover = new Select({
        condition: pointerMove,
        layers: [layer],
      });
      map.addInteraction(handleHover);
    }
  }

  removeFootprint(robotId) {
    if (!this.#footprints[robotId]) return;

    map.removeLayer(this.#footprints[robotId]);
    delete this.#footprints[robotId];
  }
  //#endregion

  //#region Private Functions
  #drawMissionMarkers(markers, color) {
    return markers.map(({ position, label }) => {
      return this.#drawMarker(position, label, color);
    });
  }

  #drawMissionPaths(robotId, markers, color) {
    const paths = [];
    markers.slice(1).forEach((currMarker, index) => {
      const prevMarker = markers[index];
      paths.push([
        fromLonLat([prevMarker.position.lng, prevMarker.position.lat]),
        fromLonLat([currMarker.position.lng, currMarker.position.lat]),
      ]);
    });

    return paths.map((path) => this.#drawPath(robotId, path, color));
  }

  #drawMarker(position, label, color) {
    const element = document.createElement('div');
    // eslint-disable-next-line react/react-in-jsx-scope
    createRoot(element).render(<Waypoint color={color} data={{ label, position }} />);

    const overlay = this.createOverlay({
      element,
      position: fromLonLat([position.lng, position.lat]),
      positioning: 'bottom-center',
      insertFirst: false,
    });
    map.addOverlay(overlay);

    return overlay;
  }

  #drawPath(robotId, path, color) {
    // 색상 RGB 값 분리
    const [r, g, b] = asArray(color);

    const feature = new Feature(new LineString(path));
    feature.setId(robotId);
    const source = new VectorSource({ features: [feature] });
    const layer = new VectorLayer({
      source,
      style: new Style({
        stroke: new Stroke({
          color: [r, g, b, 0.5],
          width: 4,
        }),
      }),
    });

    map.addLayer(layer);
    return layer;
  }
  //#endregion
}

export default Object.freeze(new OlMap());
