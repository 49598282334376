import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useContext, useState, useRef } from 'react';
import { RiLoader2Line, RiCheckboxCircleFill } from 'react-icons/ri';

import styles from './console.module.scss';

import { MessageContext } from '@/helpers/MessageProvider';
import EventEmitter from '@/libs/EventEmitter';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const Panel = ({ robot }) => {
  const { publishCommand } = useContext(MessageContext);
  const [isStarted, setStarted] = useState(false);
  const [isSuccess, setSuccess] = useState();
  const subscribeToken = useRef();
  const progressBarRef = useRef();
  const progressTextRef = useRef();

  useMountEffect(() => {
    return () => {
      publishCommand(robot, 'calibration/compass/cancel', [[]]);
      unsubscribe();
    };
  });

  const unsubscribe = () => {
    if (subscribeToken.current) {
      EventEmitter.unsubscribe(subscribeToken.current);
      subscribeToken.current = null;
    }
  };

  const doStart = async () => {
    progressBarRef.current.style.setProperty('width', '0%');
    progressTextRef.current.innerText = '0%';
    setStarted(true);

    await new Promise((resolve) => {
      subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/commandAck`, (data) => {
        // 42426: MAV_CMD_DO_CANCEL_MAG_CAL
        if (data.command === 42426 && data.result === 0) {
          unsubscribe();
          resolve();
        }
      });
      publishCommand(robot, 'calibration/compass/cancel', [[]]);
    });

    await new Promise((resolve) => {
      subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/commandAck`, (data) => {
        // 42424: MAV_CMD_DO_START_MAG_CAL
        if (data.command === 42424 && data.result === 0) {
          unsubscribe();
          resolve();
        }
      });
      publishCommand(robot, 'calibration/compass/start', [[]]);
    });

    await new Promise((resolve) => {
      subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/magCalProgress`, (data) => {
        if (data.compassId === 0) {
          let percentage = `${data.completionPct}%`;
          if (data.completionPct === 99) {
            percentage = '100%';
          }

          progressBarRef.current.style.setProperty('width', percentage);
          progressTextRef.current.innerText = percentage;

          if (data.completionPct === 99) {
            unsubscribe();
            resolve();
          }
        }
      });
    });

    await new Promise((resolve) => {
      subscribeToken.current = EventEmitter.subscribe(`${robot.id}/telemetry/magCalReport`, (data) => {
        if (data.compassId === 0) {
          unsubscribe();
          setSuccess(data.calStatus === 4);
          resolve();
        }
      });
    });
  };

  const doReboot = () => {
    publishCommand(robot, 'calibration/reboot', [[]]);
    modal.hide();
  };

  return (
    <div className={cx('container')}>
      <div className={cx('message')}>Rotate your vehicle through a number of positions.</div>
      {!isStarted && (
        <button type="button" className={cx('button')} onClick={doStart}>
          Start
        </button>
      )}
      {isStarted && isSuccess === undefined && (
        <div className={cx('status')}>
          <RiLoader2Line size={20} color="white" className={cx('spin')} />
          <div className={cx('text')}>Processing...</div>
        </div>
      )}
      <div className={cx(['progress', { show: isStarted && isSuccess === undefined }])}>
        <div ref={progressBarRef} className={cx('bar')} />
        <div ref={progressTextRef} className={cx('text')} />
      </div>
      {isSuccess && (
        <>
          <div className={cx('status')}>
            <RiCheckboxCircleFill size={20} color="#41a3ff" className={cx('message')} />
            <div className={cx('text')}>Done!</div>
          </div>
          <div className={cx('message')}>Reboot required to refresh magnetometer calibration parameters.</div>
          <button type="button" className={cx('button')} onClick={doReboot}>
            Reboot
          </button>
        </>
      )}
      {isSuccess === false && (
        <div className={cx('status')}>
          <RiCheckboxCircleFill size={20} color="red" className={cx('message')} />
          <div className={cx('text')}>Failure!</div>
        </div>
      )}
    </div>
  );
};

export default Panel;
