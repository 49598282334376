import CameraCommand from './CameraCommand';
import GimbalCommand from './GimbalCommand';
import GimbalMode from './GimbalMode';
import GimbalModeSetup from './GimbalModeSetup';
import GimbalStatus from './GimbalStatus';
import GroundWeatherData from './GroundWeatherData';
import StatationReceiveIsOn from './StatationReceiveIsOn';
import StationCommand from './StationCommand';
import StationSendIsOn from './StationSendIsOn';
import StationStatus from './StationStatus';

export {
  GroundWeatherData,
  GimbalModeSetup,
  GimbalMode,
  GimbalCommand,
  GimbalStatus,
  StationStatus,
  StationCommand,
  StationSendIsOn,
  StatationReceiveIsOn,
  CameraCommand,
};

export const REGISTRY = {
  700: GroundWeatherData,
  1000: GimbalModeSetup,
  1001: GimbalMode,
  1002: GimbalCommand,
  1003: GimbalStatus,
  3000: StationStatus,
  3001: StationCommand,
  3200: StationSendIsOn,
  3201: StatationReceiveIsOn,
  4901: CameraCommand,
};
