import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class GimbalMode extends MavLinkData {
  constructor() {
    super();
    this.gimbalMode = 0;
    this.headingMode = 0;
  }
}

GimbalMode.MSG_ID = 1001;
GimbalMode.MSG_NAME = 'GIMBAL_MODE';
GimbalMode.PAYLOAD_LENGTH = 2;
GimbalMode.MAGIC_NUMBER = 92;
GimbalMode.FIELDS = [
  new MavLinkPacketField('gimbal_mode', 'gimbalMode', 0, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('heading_mode', 'headingMode', 1, false, 1, 'uint8_t', ''),
];

export default GimbalMode;
