import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import * as turf from '@turf/turf';
import classNames from 'classnames/bind';
import { Draw } from 'ol/interaction';
import { toLonLat } from 'ol/proj';
import VectorSource from 'ol/source/Vector';
import { Style, Stroke, Fill } from 'ol/style';
import React, { useRef, useMemo } from 'react';
import { RiCircleLine } from 'react-icons/ri';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './index.module.scss';

import actions from '@/actions';
import { SURVEY_DEFAULT_OPTIONS } from '@/config';
import OlMap from '@/helpers/OlMap';
import { ToastService as toast } from '@/libs/Toast';
import CoordUtils from '@/utils/CoordUtils';
import { getPositions } from '@/utils/SurveyCalculator';

const cx = classNames.bind(styles);

const style = new Style({
  stroke: new Stroke({ color: 'black', width: 4 }),
  fill: new Fill({ color: [0, 0, 0, 0.3] }),
});

const DrawArea = () => {
  const store = useStore();
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.editor.mode);
  const interaction = useRef();
  const map = OlMap.getMap();

  const isActive = useMemo(() => {
    return mode === 'area';
  }, [mode]);

  useUpdateEffect(() => {
    if (!isActive) return;

    const source = new VectorSource({ wrapX: false });

    interaction.current = new Draw({
      source: source.current,
      type: 'Polygon',
      freehand: true,
      style,
    });
    interaction.current.on('drawend', drawArea);
    map.addInteraction(interaction.current);

    return () => {
      map.removeInteraction(interaction.current);
      interaction.current = null;
    };
  }, [isActive]);

  const drawArea = (e) => {
    // 모든 좌표
    const allCoords = e.feature.getGeometry().getCoordinates()[0];
    // 중복 제거된 좌표
    const coords = allCoords.filter((coord, index) => {
      const curr = JSON.stringify(coord);
      const next = JSON.stringify(allCoords[index + 1]);
      return curr !== next;
    });

    const polygon = turf.polygon([coords]);
    const selfIntersections = turf.kinks(polygon).features;
    // 자기교차점 존재 시
    if (selfIntersections.length > 0) {
      toast.error('The drawn area cannot have self-intersections. Please redraw.');
      return;
    }

    const boundary = coords.map((coord) => {
      return CoordUtils.objectFromArray(toLonLat(coord));
    });
    const positions = getPositions(boundary, SURVEY_DEFAULT_OPTIONS);
    // 경로점 미존재 시
    if (positions.length === 0) {
      toast.error('The area is too small. Please redraw.');
      return;
    }

    dispatch(actions.editor.appendSurvey(boundary, positions));
    dispatch(actions.editor.changeMode(null));
  };

  const toggle = () => {
    if (store.getState().editor.missionItems.length === 0) {
      toast.error('Please add a takeoff point first.');
      return;
    }

    if (isActive) {
      dispatch(actions.editor.changeMode(null));
    } else {
      dispatch(actions.editor.changeMode('area'));
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx(['button', { active: isActive }])} onClick={toggle}>
        <RiCircleLine size={16} />
        <div className={cx('label')}>Area</div>
      </div>
    </div>
  );
};

export default DrawArea;
