import { useState, useEffect } from 'react';

import EventEmitter from '@/libs/EventEmitter';

const useWaitingRobot = (robotId) => {
  const [isWaiting, setIsWaiting] = useState(true);

  useEffect(() => {
    EventEmitter.subscribe(`${robotId}/event/waitingRobot`, () => {
      setIsWaiting(true);
    });

    EventEmitter.subscribe(`${robotId}/event/reconnectRobot`, () => {
      setIsWaiting(false);
    });
  }, []);

  return [isWaiting];
};

export default useWaitingRobot;
