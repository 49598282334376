import ArduCopter from '@/models/mavlink2-ardupilot-copter';
import ArduFW from '@/models/mavlink2-ardupilot-fw';
import ArduVTOL from '@/models/mavlink2-ardupilot-vtol';
import PX4Moonboat from '@/models/mavlink2-px4-moonboat';
import PX4Sheco from '@/models/mavlink2-px4-sheco';
import QTCopter from '@/models/mavlink2-quaternion-copter';
import QTStation from '@/models/mavlink2-quaternion-station';
import UnitreeB2 from '@/models/ros2-unitree-b2';

const MODELS = {
  // ArduPilot Copter
  '0ae6489b-7fd4-4d9d-8b47-a8c6c7e669ca': ArduCopter,
  // ArduPilot Fixed-wing
  '1a90a54a-e98c-4b26-89db-8f7b94fc065c': ArduFW,
  // ArduPilot VTOL
  '85570cb9-64ee-46b8-b612-d8b07813d5ef': ArduVTOL,
  // PX4 Moonboat
  '04460d4f-7139-4a4f-9971-921d7a05e764': PX4Moonboat,
  // PX4 Sheco
  'd212a420-d77f-4b32-baf2-0e5095393037': PX4Sheco,
  // Quaternion Copter
  'b5e665d7-dab4-41f0-84ca-df565269a256': QTCopter,
  // Quaternion Station
  'b64ff830-1550-4f9e-8143-da79f5b0fd1c': QTStation,
  // Unitree B2
  'a1dd3fee-3a72-4d33-9079-2aa73918cc39': UnitreeB2,
};

export default MODELS;
