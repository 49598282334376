import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    NOTIFY: (robotId, type, text) => ({ robotId, type, text }),
    REMOVE: (robotId) => ({ robotId }),
  },
  { prefix: 'NOTIFICATION' }
);
