import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class GimbalModeSetup extends MavLinkData {
  constructor() {
    super();
    this.gimbal = 0;
    this.heading = 0;
    this.control = 0;
  }
}

GimbalModeSetup.MSG_ID = 1000;
GimbalModeSetup.MSG_NAME = 'GIMBAL_MODE_SETUP';
GimbalModeSetup.PAYLOAD_LENGTH = 3;
GimbalModeSetup.MAGIC_NUMBER = 180;
GimbalModeSetup.FIELDS = [
  new MavLinkPacketField('gimbal', 'gimbal', 0, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('heading', 'heading', 1, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('control', 'control', 2, false, 1, 'uint8_t', ''),
];

export default GimbalModeSetup;
