import classNames from 'classnames/bind';
import React, { useRef, useMemo } from 'react';
import { MdOutlineOpenInNew, MdOutlineCameraAlt } from 'react-icons/md';

import styles from './ViewFinder.module.scss';

import { VIDEO_DEFAULT_WIDTH } from '@/config';

const cx = classNames.bind(styles);

const ViewFinder = ({ robotId, video }) => {
  const viewFinderRef = useRef();

  const streamUrl = useMemo(() => {
    return `${process.env.REACT_APP_KURENTO_URL}/viewer.html?robotId=${robotId}&deviceId=${video.deviceId}`;
  }, [robotId, video]);

  const openWindow = (e) => {
    e.stopPropagation();
    window.open(streamUrl);
  };

  const capture = (e) => {
    e.stopPropagation();
    viewFinderRef.current.contentWindow.postMessage('capture', process.env.REACT_APP_KURENTO_URL);
  };

  return (
    <div className={cx('container')}>
      <iframe
        ref={viewFinderRef}
        src={streamUrl}
        title={video.label}
        width={VIDEO_DEFAULT_WIDTH}
        height={VIDEO_DEFAULT_WIDTH / video.ratio}
        className={cx('viewFinder')}
      />
      <div className={cx('overlay')}>
        <div onClick={openWindow} className={cx('button')}>
          <MdOutlineOpenInNew size={16} color="white" title="New Window" />
        </div>
        {process.env.REACT_APP_USE_CAPTURE === 'true' && (
          <div onClick={capture} className={cx('button')}>
            <MdOutlineCameraAlt size={16} color="white" title="Capture" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewFinder;
