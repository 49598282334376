import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class GroundWeatherData extends MavLinkData {
  constructor() {
    super();
    this.longitude = 0;
    this.latitude = 0;
    this.altitude = 0;
    this.windDirection = 0;
    this.windSpeed = 0;
    this.temperature = 0;
    this.humidity = 0;
    this.groundId = 0;
  }
}

GroundWeatherData.MSG_ID = 700;
GroundWeatherData.MSG_NAME = 'GROUND_WEATHER_DATA';
GroundWeatherData.PAYLOAD_LENGTH = 20;
GroundWeatherData.MAGIC_NUMBER = 131;
GroundWeatherData.FIELDS = [
  new MavLinkPacketField('longitude', 'longitude', 0, false, 4, 'int32_t', ''),
  new MavLinkPacketField('latitude', 'latitude', 4, false, 4, 'int32_t', ''),
  new MavLinkPacketField('altitude', 'longitude', 8, false, 2, 'int16_t', ''),
  new MavLinkPacketField('windDirection', 'windDirection', 10, false, 2, 'int16_t', ''),
  new MavLinkPacketField('windSpeed', 'windSpeed', 12, false, 2, 'int16_t', ''),
  new MavLinkPacketField('temperature', 'temperature', 14, false, 2, 'int16_t', ''),
  new MavLinkPacketField('humidity', 'humidity', 16, false, 2, 'int16_t', ''),
  new MavLinkPacketField('groundId', 'groundId', 18, false, 2, 'int16_t', ''),
];

export default GroundWeatherData;
