import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class StationSendIsOn extends MavLinkData {
  constructor() {
    super();
    this.voltage = 0;
    this.ampere = 0;
    this.id = 0;
    this.command = 0;
  }
}

StationSendIsOn.MSG_ID = 3200;
StationSendIsOn.MSG_NAME = 'STATION_SEND_IS_ON';
StationSendIsOn.PAYLOAD_LENGTH = 6;
StationSendIsOn.MAGIC_NUMBER = 46;
StationSendIsOn.FIELDS = [
  new MavLinkPacketField('voltage', 'voltage', 0, false, 2, 'uint16_t', 'V'),
  new MavLinkPacketField('ampere', 'ampere', 2, false, 2, 'uint16_t', 'A'),
  new MavLinkPacketField('id', 'id', 4, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('command', 'command', 5, false, 1, 'uint8_t', ''),
];

export default StationSendIsOn;
