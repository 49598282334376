import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { MdOutlineInfo } from 'react-icons/md';

import styles from './Body.module.scss';

import { Row } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Body = ({ robotId }) => {
  const [drone, setDrone] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/drone`, setDrone);

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return (
    <div className={cx('container')}>
      {drone && (
        <div className={cx('values')}>
          {Object.entries(drone)
            .sort((a, b) => (a[0] < b[0] ? -1 : 1))
            .map(([key, value], index) => {
              return <Row key={index} icon={MdOutlineInfo} label={key} value={value} unit="_" />;
            })}
        </div>
      )}
    </div>
  );
};

export default Body;
