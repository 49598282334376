import produce from 'immer';
import moment from 'moment';
import { handleActions } from 'redux-actions';

const initialState = {};

export default handleActions(
  {
    'NOTIFICATION/RESET': (state, action) => initialState,
    'NOTIFICATION/NOTIFY': (state, action) =>
      produce(state, (draft) => {
        const { robotId, type, text } = action.payload;
        const notification = { type, text, time: moment().format('HH:mm:ss') };

        if (state[robotId]) {
          draft[robotId].unshift(notification);
        } else {
          draft[robotId] = [notification];
        }
      }),
    'NOTIFICATION/REMOVE': (state, action) =>
      produce(state, (draft) => {
        const { robotId } = action.payload;
        delete draft[robotId];
      }),
  },
  initialState
);
