import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import React, { useMemo, useState } from 'react';
import {
  MdOutlineSettings,
  MdOutlineNotifications,
  MdOutlineCenterFocusStrong,
  MdOutlineChat,
  MdOutlineFeaturedVideo,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ControlBar.module.scss';
import Notifications from './Notifications';

import actions from '@/actions';
import SettingsModal from '@/components/modals/Settings';
import { ModalService as modal } from '@/libs/Modal';

const cx = classNames.bind(styles);

const ControlBar = ({ robot, settings = false }) => {
  const dispatch = useDispatch();
  const activeRobotIds = useSelector((state) => state.robot.activeRobotIds);
  const options = useSelector((state) => state.robot.options[robot.id]);
  const focusRobotId = useSelector((state) => state.robot.focusRobotId);
  const notifications = useSelector((state) => state.notification[robot.id]);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);

  // 연결 여부
  const isActive = useMemo(() => activeRobotIds.includes(robot.id), [activeRobotIds]);

  useUpdateEffect(() => {
    // 로봇 연결 해제된 경우
    if (!activeRobotIds.includes(robot.id)) {
      setIsOpenNotifications(false);
    }
  }, [activeRobotIds]);

  const toggleFocus = (e) => {
    e.stopPropagation();

    if (focusRobotId === robot.id) {
      dispatch(actions.robot.setFocus());
    } else {
      dispatch(actions.robot.setFocus(robot.id));
    }
  };

  const toggleOption = (e) => {
    e.stopPropagation();

    const { option } = e.currentTarget.dataset;
    dispatch(actions.robot.toggleOption(robot.id, option));
  };

  const toggleNotifications = (e) => {
    e.stopPropagation();
    setIsOpenNotifications(!isOpenNotifications);
  };

  const openSettings = (e) => {
    e.stopPropagation();
    modal.show(SettingsModal, { robot });
  };

  if (!isActive) return;

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('side')}>
          <div className={cx(['button', { active: isOpenNotifications }])} onClick={toggleNotifications}>
            <MdOutlineNotifications size={16} />
            <div className={cx('value')}>{notifications?.length ?? 0}</div>
          </div>
          {robot.isOwned && settings && (
            <div className={cx('button')} onClick={openSettings}>
              <MdOutlineSettings size={16} />
            </div>
          )}
        </div>
        <div className={cx('side')}>
          <div className={cx(['button', { active: focusRobotId === robot.id }])} onClick={toggleFocus}>
            <MdOutlineCenterFocusStrong size={16} title="Set Focus" />
          </div>
          <div data-option="label" className={cx(['button', { active: options.label }])} onClick={toggleOption}>
            <MdOutlineChat size={16} title="Show Label" />
          </div>
          <div data-option="video" className={cx(['button', { active: options.video }])} onClick={toggleOption}>
            <MdOutlineFeaturedVideo size={16} title="Show Video" />
          </div>
        </div>
      </div>
      {isOpenNotifications && <Notifications robotId={robot.id} />}
    </div>
  );
};

export default ControlBar;
