import useMountEffect from '@restart/hooks/useMountEffect';
import { useRef } from 'react';

import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';

const useFootprint = (robotId) => {
  const prevPosition = useRef();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/globalPosition`, ({ lat, lng }) => {
      const prevLatLng = prevPosition.current;

      // 현재 위치와 이전 위치가 같지 않은 경우에만 업데이트
      if (prevLatLng?.lat === lat && prevLatLng?.lng === lng) return;

      prevPosition.current = { lat, lng };
      OlMap.addFootprint(robotId, { lat, lng });
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
      OlMap.removeFootprint(robotId);
    };
  });
};

export default useFootprint;
