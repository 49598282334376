import useMountEffect from '@restart/hooks/useMountEffect';
import _ from 'lodash';
import { MavLinkPacketParser, MavLinkProtocolV2, common, minimal, ardupilotmega } from 'node-mavlink';
import React, { createContext, useRef } from 'react';
import { useDispatch, useStore } from 'react-redux';

import actions from '@/actions';
import { isElectron, isBrowser } from '@/environment';
import * as sheco from '@/models/mavlink2-px4-sheco/messages';
import * as quaternion from '@/models/mavlink2-quaternion-station/messages';

const MessageContext = createContext(null);
export { MessageContext };

const Provider = ({ children }) => {
  const dispatch = useDispatch();
  const store = useStore();
  const mavlinkParser = useRef();
  const mavlinkProtocol = useRef();
  const mqttWorker = useRef();
  const robotMeta = useRef({});
  const registry = useRef({
    ...minimal.REGISTRY,
    ...common.REGISTRY,
    ...ardupilotmega.REGISTRY,
    ...quaternion.REGISTRY,
    ...sheco.REGISTRY,
  });

  useMountEffect(() => {
    mavlinkParser.current = new MavLinkPacketParser();
    mavlinkProtocol.current = new MavLinkProtocolV2();

    // Electron 실행 시
    if (isElectron) {
      // 메시지 수신 처리
      window.electron.ipcRenderer.on('message', messageHandler);
    }

    // Browser 실행 시
    if (isBrowser) {
      // MQTT Worker 정의
      mqttWorker.current = new Worker('./workers/mqtt-worker.js');
      // MQTT 연결
      mqttWorker.current.postMessage({
        command: 'connect',
        data: {
          brokerUrl: process.env.REACT_APP_MQTT_URL,
          username: process.env.REACT_APP_MQTT_USERNAME,
          password: process.env.REACT_APP_MQTT_PASSWORD,
        },
      });
      // 메시지 수신 처리
      mqttWorker.current.onmessage = ({ data }) => messageHandler(data);
    }
  });

  const messageHandler = ({ robotId, type, message }) => {
    // 텔레메트리 수신 전 로봇 메타 데이터 초기화되지 않은 상태 시
    if (!robotMeta.current[robotId]) return;

    // 활성 로봇이 아닌 경우
    if (!store.getState().robot.activeRobotIds.includes(robotId)) {
      // 로봇 활성화
      dispatch(actions.robot.activate(robotId));
    }

    switch (type) {
      case 'telemetry':
        switch (robotMeta.current[robotId].agentType) {
          case 'MavLink':
            handleMessageMavLink(robotId, message);
            break;

          case 'ROS':
            handleMessageRos(robotId, message);
            break;

          default:
            break;
        }
        break;

      case 'iothub':
        handleIoTHubMessage(robotId, message);
        break;

      default:
        break;
    }
  };

  const subscribeTelemetry = (robots) => {
    robots.forEach((robot) => {
      robotMeta.current[robot.id] = {
        commandSeq: 0,
        agentType: robot.model.agentType,
      };

      // Electron 실행 시
      if (isElectron) {
        // SerialPort 연결
        window.electron.ipcRenderer.send('connect', {
          path: robot.path,
          robotId: robot.id,
        });
      }

      // Browser 실행 시
      if (isBrowser) {
        // 메시지 구독 요청
        mqttWorker.current.postMessage({
          command: 'subscribe',
          data: {
            topics: [
              `robots/${robot.id}/telemetry`, // 텔레메트리
              `robots/${robot.id}/iothub`, // IoTHub
            ],
          },
        });
      }
    });
  };

  // MavLink 메시지 처리
  const handleMessageMavLink = (robotId, message) => {
    mavlinkParser.current._transform({ buffer: Buffer.from(message) }, 'utf8', (_, packet) => {
      // MavLink v1 메시지 처리 제외
      // https://mavlink.io/en/guide/serialization#mavlink2_packet_format
      if (packet.header.magic === 0) {
        console.debug('packet.header.magic', 0);
        return;
      }

      const messageClass = registry.current[packet.header.msgid];
      if (!messageClass) return;

      const parsed = mavlinkProtocol.current.data(packet.payload, messageClass);
      const payload = {};
      Object.entries(parsed).forEach(([key, value]) => {
        if (typeof value === 'bigint') return;

        payload[key] = value;
      });

      dispatch(
        actions.telemetry.loadMavlink({
          robotId,
          msgId: packet.header.msgid,
          payload,
        })
      );
    });
  };

  // ROS 메시지 처리
  const handleMessageRos = (robotId, message) => {
    const data = JSON.parse(Buffer.from(message).toString());

    dispatch(
      actions.telemetry.loadRos({
        robotId,
        topic: data.topic,
        payload: data.message.data,
      })
    );
  };

  // IoTHub 메시지 처리
  const handleIoTHubMessage = (robotId, message) => {
    const payload = JSON.parse(Buffer.from(message).toString());

    dispatch(actions.event.load({ robotId, payload }));
  };

  const publishCommand = (robot, name, value) => {
    // 텔레메트리 수신 전 로봇 메타 데이터 초기화되지 않은 상태 시
    if (!robotMeta.current[robot.id]) return;

    switch (robot.model.agentType) {
      case 'MavLink':
        publishCommandMavLink(robot, name, value);
        break;

      case 'ROS':
        publishCommandRos(robot, name, value);
        break;

      default:
        break;
    }
  };

  // MavLink 명령 전송
  const publishCommandMavLink = (robot, name, paramsSet) => {
    const robotId = robot.id;
    const modelId = robot.model.id;

    const { sysId, compId, messages } = store.getState().commandSet[modelId][name];
    const protocol = new MavLinkProtocolV2(sysId, compId);

    messages.forEach((message) => {
      const { isMultiple, msgId, fields } = message;
      const paramsGroup = [];

      // 반복 메시지 경우
      if (isMultiple) {
        paramsSet.shift().forEach((params) => paramsGroup.push(params));
      }
      // 단일 메시지 경우
      else {
        paramsGroup.push(paramsSet.shift());
      }

      paramsGroup.forEach((params) => {
        const mavMessage = new registry.current[msgId]();
        const mavMessageFields = registry.current[msgId].FIELDS;

        fields.forEach((field) => {
          const { name } = mavMessageFields.find(({ source }) => source === field.name);

          // 변수인 경우
          if (field.isVariable) {
            mavMessage[name] = params.shift();
          } else {
            mavMessage[name] = field.value;
          }
        });

        const seq = robotMeta.current[robotId].commandSeq;
        robotMeta.current[robotId].commandSeq = (seq + 1) % 256;
        const serialized = protocol.serialize(mavMessage, seq);

        // Electron 실행 시
        if (isElectron) {
          window.electron.ipcRenderer.send('command', {
            robotId,
            message: serialized,
          });
        }

        // Browser 실행 시
        if (isBrowser) {
          mqttWorker.current.postMessage({
            command: 'publish',
            data: {
              topic: `robots/${robotId}/command`,
              message: serialized,
            },
          });
        }
      });
    });
  };

  // ROS 명령 전송
  const publishCommandRos = (robot, name, param) => {
    const robotId = robot.id;
    const modelId = robot.model.id;

    const { type, topic, messageTemplate } = store.getState().commandSet[modelId][name];
    const compiled = _.template(messageTemplate);
    const data = compiled(param);

    // Electron 실행 시
    if (isElectron) {
      // TODO
    }

    // Browser 실행 시
    if (isBrowser) {
      mqttWorker.current.postMessage({
        command: 'publish',
        data: {
          topic: `robots/${robotId}/command`,
          message: JSON.stringify({
            topic,
            message: { type, data },
          }),
        },
      });
    }
  };

  const startTracker = (robotId, operationId) => {
    // Browser 실행 시
    if (isBrowser) {
      mqttWorker.current.postMessage({
        command: 'publish',
        data: {
          topic: `robots/${robotId}/tracker`,
          message: JSON.stringify({ operationId }),
        },
      });
    }
  };

  return (
    <MessageContext.Provider value={{ subscribeTelemetry, publishCommand, startTracker }}>
      {children}
    </MessageContext.Provider>
  );
};

export default Provider;
