import classNames from 'classnames/bind';
import React from 'react';

import styles from './ModalSpinner.module.scss';

import spinner from '@/assets/images/spinner.svg';

const cx = classNames.bind(styles);

const ModalSpinner = () => {
  return (
    <div className={cx('container')}>
      <img src={spinner} alt="Loading" />
    </div>
  );
};

export default ModalSpinner;
