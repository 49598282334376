import {
  RiAlignVertically,
  RiUploadLine,
  RiDownloadLine,
  RiLockLine,
  RiAiGenerate,
  RiBodyScanLine,
  RiWalkLine,
  RiRunLine,
  RiSoundModuleLine,
} from 'react-icons/ri';

// Sport Mode
import Damp from './01_SportMode/01_Damp';
import StandUp from './01_SportMode/02_StandUp';
import CrouchDown from './01_SportMode/03_CrouchDown';
import LockOn from './01_SportMode/04_LockOn';
import Pose from './01_SportMode/05_Pose';
import KeepWalking from './01_SportMode/06_KeepWalking';
import Run from './01_SportMode/07_Run';
// Config
import AIMode from './02_Config/01_AIMode';
import Settings from './02_Config/02_Settings';

const commands = [
  {
    name: 'Sport Mode',
    subcommands: [
      { name: 'Damp', icon: RiAlignVertically, component: Damp },
      { name: 'Stand Up', icon: RiUploadLine, component: StandUp },
      { name: 'Crouch Down', icon: RiDownloadLine, component: CrouchDown },
      { name: 'Lock On', icon: RiLockLine, component: LockOn },
      { name: 'Pose', icon: RiBodyScanLine, component: Pose },
      { name: 'Keep Walking', icon: RiWalkLine, component: KeepWalking },
      { name: 'Run', icon: RiRunLine, component: Run },
    ],
  },
  {
    name: 'Config',
    subcommands: [
      { name: 'AI Mode', icon: RiAiGenerate, component: AIMode },
      { name: 'Settings', icon: RiSoundModuleLine, component: Settings },
    ],
  },
];

export default commands;
