import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class StationStatus extends MavLinkData {
  constructor() {
    super();
    this.servo = 0;
    this.temp = 0;
    this.connect = 0;
    this.homing = 0;
    this.error = 0;
    this.step = 0;
    this.progress = 0;
  }
}

StationStatus.MSG_ID = 3000;
StationStatus.MSG_NAME = 'STATION_STATUS';
StationStatus.PAYLOAD_LENGTH = 9;
StationStatus.MAGIC_NUMBER = 20;
StationStatus.FIELDS = [
  new MavLinkPacketField('servo', 'servo', 0, false, 2, 'int16_t', ''),
  new MavLinkPacketField('temp', 'temp', 2, false, 2, 'int16_t', ''),
  new MavLinkPacketField('connect', 'connect', 4, false, 1, 'int8_t', ''),
  new MavLinkPacketField('homing', 'homing', 5, false, 1, 'int8_t', ''),
  new MavLinkPacketField('error', 'error', 6, false, 1, 'int8_t', ''),
  new MavLinkPacketField('door', 'door', 7, false, 1, 'int8_t', ''),
  new MavLinkPacketField('progress', 'progress', 8, false, 1, 'int8_t', ''),
];

export default StationStatus;
