import axios from 'axios';

import { outboundLog, inboundLog } from '@/utils/Logger';

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

const token = localStorage.getItem('@m1ucs/token');
if (token) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

// Request intercept
axiosInstance.interceptors.request.use(
  (config) => {
    outboundLog('API', { method: config.method, url: config.url });
    return config;
  },
  (error) => Promise.reject(error)
);
// Response intercept
axiosInstance.interceptors.response.use(
  ({ data }) => {
    // Invalid access token
    if (!data.success && data.data.code === 4001) {
      removeAuthToken();
      window.location.href = '/';
      return;
    }

    inboundLog('API', data, data.success);
    return data;
  },
  (error) => {
    return {
      success: false,
      data: { error },
    };
  }
);

export default axiosInstance;

export const setAuthToken = (newToken) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
  localStorage.setItem('@m1ucs/token', newToken);
};

export const getAuthToken = () => {
  return localStorage.getItem('@m1ucs/token');
};

export const removeAuthToken = () => {
  delete axiosInstance.defaults.headers.common['Authorization'];
  localStorage.removeItem('@m1ucs/token');
};
