import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class StatationReceiveIsOn extends MavLinkData {
  constructor() {
    super();
    this.chargingDuration = 0;
    this.batteryInfo = 0;
    this.voltage = 0;
    this.ampere = 0;
    this.temp = 0;
    this.windDirection = 0;
    this.id = 0;
    this.chargingStatus = 0;
    this.droneStatus = 0;
    this.droneId = 0;
    this.batteryNumber = 0;
    this.ccCv = 0;
    this.doorStatus = 0;
    this.movingStatus = 0;
    this.humidity = 0;
    this.windSpeed = 0;
  }
}
StatationReceiveIsOn.MSG_ID = 3201;
StatationReceiveIsOn.MSG_NAME = 'STATION_RECEIVE_IS_ON';
StatationReceiveIsOn.PAYLOAD_LENGTH = 14;
StatationReceiveIsOn.MAGIC_NUMBER = 228;
StatationReceiveIsOn.FIELDS = [
  new MavLinkPacketField('charging_duration', 'chargingDuration', 0, false, 4, 'uint32_t', ''),
  new MavLinkPacketField('battery_info', 'batterInfo', 4, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('voltage', 'voltage', 6, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('ampere', 'ampere', 8, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('temp', 'temp', 10, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('wind_direction', 'wind_direction', 12, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('id', 'id', 14, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('charging_status', 'chargingStatus', 15, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('drone_status', 'droneStatus', 16, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('drone_id', 'droneId', 17, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('battery_number', 'batteryNumber', 18, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('cc_cv', 'ccCv', 19, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('door_status', 'doorStatus', 20, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('moving_status', 'movingStatus', 21, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('humidity', 'humidity', 22, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('wind_speed', 'windSpeed', 23, false, 1, 'uint8_t', ''),
];

export default StatationReceiveIsOn;
