import { createActions } from 'redux-actions';

export default createActions(
  {
    CHANGE_MODE: (mode) => ({ mode }),
    CHANGE_NAME: (name) => ({ name }),
    SET_CONFIG: (config) => ({ config }),
    LOAD: (mission) => mission,
    SAVE: () => ({}),
    // Waypoint
    APPEND_WAYPOINT: (waypoint) => ({ waypoint }),
    INSERT_WAYPOINT: (index, waypoint) => ({ index, waypoint }),
    MOVE_WAYPOINT: (id, waypoint) => ({ id, waypoint }),
    EDIT_WAYPOINT: (index, name, value) => ({ index, name, value }),
    EDIT_ALL_WAYPOINTS: (name, value) => ({ name, value }),
    // Survey
    APPEND_SURVEY: (boundary, positions) => ({ boundary, positions }),
    EDIT_SURVEY: (index, values) => ({ index, values }),
    // Mission
    RESET_MISSION_ITEMS: () => ({}),
    REMOVE_MISSION_ITEM: (index) => ({ index }),
    CHANGE_MISSION_ITEM_TYPE: (index, type) => ({ index, type }),
    APPEND_RETURN_TO_LAUNCH: () => ({}),
  },
  { prefix: 'EDITOR' }
);
