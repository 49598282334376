import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import commaNumber from 'comma-number';
import moment from 'moment';
import React, { useRef, useMemo, useState } from 'react';
import { RiRefreshLine, RiCheckboxMultipleLine, RiSettings5Line } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import MissionItem from './MissionItem';

import actions from '@/actions';
import EditorBatchModal from '@/components/modals/EditorBatch';
import Bar from '@/components/ui/Bar';
import EmptyList from '@/components/ui/EmptyList';
import { ModalService as modal } from '@/libs/Modal';
import { ToastService as toast } from '@/libs/Toast';
import { getDistanceAlongPath } from '@/utils/MapUtils';

const cx = classNames.bind(styles);

const Panel = () => {
  const dispatch = useDispatch();
  const name = useSelector((state) => state.editor.name);
  const missionItems = useSelector((state) => state.editor.missionItems);
  const [showSpeedPanel, setShowSpeedPanel] = useState(false);
  const [speed, setSpeed] = useState(10);
  const speedRef = useRef();

  const totalDistance = useMemo(() => {
    const path = [];
    missionItems.forEach((missionItem) => {
      switch (missionItem.type) {
        case 'navLand':
        case 'navLoiterToAlt':
        case 'navTakeoff':
        case 'navWaypoint':
          path.push(missionItem.data.position);
          break;

        case 'navReturnToLaunch':
          path.push(missionItems[0].data.position);
          break;

        case 'cusSurvey':
          missionItem.data.positions.forEach((position) => path.push(position));
          break;

        default:
          break;
      }
    });

    return getDistanceAlongPath(path);
  }, [missionItems]);

  const time = useMemo(() => {
    const value = totalDistance / speed;

    if (Number.isFinite(value) && value > 0) {
      return value * 1000;
    }
    return 0;
  }, [totalDistance, speed]);

  useUpdateEffect(() => {
    if (showSpeedPanel) {
      speedRef.current.focus();
    }
  }, [showSpeedPanel]);

  const handleChangeName = (e) => {
    dispatch(actions.editor.changeName(e.target.value));
  };

  const toggleSpeedPanel = () => {
    setShowSpeedPanel(!showSpeedPanel);
  };

  const changeSpeed = (e) => {
    setSpeed(Number(e.target.value));
  };

  const resetMissionItems = () => {
    dispatch(actions.editor.resetMissionItems());
  };

  const showEditorBatch = () => {
    modal.show(EditorBatchModal);
  };

  const addCommand = (e) => {
    if (missionItems.length === 0) {
      toast.error('Please add a takeoff point first.');
      return;
    }

    switch (e.target.value) {
      case 'returnToLaunch':
        dispatch(actions.editor.appendReturnToLaunch());
        break;

      default:
        break;
    }
  };

  return (
    <div className={cx('container')}>
      <div className={cx('header1')}>
        <input
          type="text"
          value={name}
          maxLength={24}
          placeholder="Name"
          className={cx('field')}
          onChange={handleChangeName}
        />
      </div>
      <div className={cx('header2')}>
        <div className={cx('summary')}>
          <div className={cx('label')}>Total</div>
          <div className={cx('values')}>
            <div className={cx('item')}>
              {commaNumber(missionItems.length)} <span>items</span>
            </div>
            <Bar />
            <div className={cx('item')}>
              {commaNumber((totalDistance / 1000).toFixed(1))}
              <span>km</span>
            </div>
            <Bar />
            <div className={cx('item')}>{moment.utc(time).format('HH:mm:ss')}</div>
            <div className={cx('speedSettings')}>
              <RiSettings5Line size={14} color="gray" onClick={toggleSpeedPanel} className={cx('icon')} />
              <div className={cx(['panel', { show: showSpeedPanel }])}>
                <div className={cx('arrow')} />
                <label>
                  Average Speed
                  <div className={cx('fieldWrapper')}>
                    <input
                      ref={speedRef}
                      name="speed"
                      type="number"
                      defaultValue={speed}
                      onChange={changeSpeed}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          toggleSpeedPanel();
                        }
                      }}
                    />
                    <span className={cx('unit')}>m/s</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className={cx('buttons')}>
          <button type="button" className={cx('button')} onClick={resetMissionItems}>
            <RiRefreshLine size={12} color="white" />
            <div className={cx('label')}>Reset</div>
          </button>
          <button type="button" className={cx('button')} onClick={showEditorBatch}>
            <RiCheckboxMultipleLine size={12} color="white" />
            <div className={cx('label')}>Edit All</div>
          </button>
        </div>
      </div>
      <div className={cx('body')}>
        {missionItems.length === 0 && (
          <div className={cx('empty')}>
            <EmptyList message="No items" />
          </div>
        )}
        {missionItems.length > 0 && (
          <div className={cx('inner')}>
            {missionItems.map((missionItem, index) => (
              <MissionItem key={missionItem.id} index={index} data={missionItem} />
            ))}
          </div>
        )}
      </div>
      <div className={cx('bottom')}>
        <div className={cx('title')}>Add Command</div>
        <select value="" onChange={addCommand}>
          <option value="">Select</option>
          <option value="returnToLaunch">Return To Launch</option>
        </select>
      </div>
    </div>
  );
};

export default Panel;
