import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import classNames from 'classnames/bind';
import { DragPan } from 'ol/interaction';
import { fromLonLat } from 'ol/proj';
import React, { useRef } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './index.module.scss';
import Mappers from './Mappers';
import MapToolbar from './MapToolbar';

import actions from '@/actions';
import Cross from '@/components/ui/map/Cross';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const Map = () => {
  const dispatch = useDispatch();
  const focusRobotId = useSelector((state) => state.robot.focusRobotId);
  const store = useStore();
  const map = useRef();
  const mapRef = useRef();

  useMountEffect(() => {
    // redux-persist 활용한 최종 center 및 zoom 적용
    const { center, zoom } = store.getState().map;
    map.current = OlMap.init(mapRef.current, { center, zoom });

    setTimeout(() => OlMap.fitBoundsAuto(), 1000);

    // 지도 이동 중일 시
    map.current.getView().on('change:center', () => {
      const { lat, lng } = OlMap.getCenter();
      dispatch(actions.map.setCenter(lat, lng));
    });

    // 지도 이동 또는 줌 완료 시
    map.current.on('moveend', () => {
      const { lat, lng } = OlMap.getCenter();
      const zoom = map.current.getView().getZoom();

      dispatch(actions.map.setPositionAndZoom(lat, lng, zoom));
    });
  });

  useUpdateEffect(() => {
    const dragPan = map.current
      .getInteractions()
      .getArray()
      .find((interaction) => interaction instanceof DragPan);

    // Focus 설정된 로봇이 존재하지 않는 경우 지도 drag 허용
    dragPan.setActive(!focusRobotId);

    let subscribeToken;
    // Focus 설정된 로봇이 존재하는 경우
    if (focusRobotId) {
      subscribeToken = EventEmitter.subscribe(`${focusRobotId}/telemetry/globalPosition`, ({ lat, lng }) => {
        if (OlMap.checkCurrentVWorld() && !OlMap.checkInsideOfVWorld([lng, lat])) {
          return;
        }

        map.current.getView().animate({
          center: fromLonLat([lng, lat]),
          duration: 0,
        });
      });
    }

    return () => {
      if (subscribeToken) {
        EventEmitter.unsubscribe(subscribeToken);
      }
    };
  }, [focusRobotId]);

  return (
    <div className={cx('container')}>
      <div ref={mapRef} className={cx('map')} />
      <Mappers />
      <MapToolbar />
      <Cross />
    </div>
  );
};

export default Map;
