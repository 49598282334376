import classNames from 'classnames/bind';
import React, { useEffect, useMemo, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import SVG from 'react-inlinesvg';
import { Provider } from 'react-redux';
import { useDispatch, useSelector, useStore } from 'react-redux';

import styles from './Robot.module.scss';

import actions from '@/actions';
import OlMap from '@/helpers/OlMap';
import EventEmitter from '@/libs/EventEmitter';

const cx = classNames.bind(styles);

const MarkerContent = ({ data: robot }) => {
  const targetRobotIds = useSelector((state) => state.robot.targetRobotIds);
  const showLabel = useSelector((state) => state.robot.options[robot.id]?.label);
  const robotRef = useRef();
  const altitudeRef = useRef();
  const speedRef = useRef();

  const scale = useMemo(() => {
    return targetRobotIds.includes(robot.id) ? 1.2 : 1;
  }, [targetRobotIds]);

  useEffect(() => {
    const subscribeTokens = [];
    subscribeTokens.push(
      EventEmitter.subscribe(`${robot.id}/telemetry/globalPosition`, ({ hdg }) => {
        robotRef.current.style.transform = `rotate(${hdg}deg)`;
      })
    );

    if (showLabel) {
      subscribeTokens.push(
        EventEmitter.subscribe(`${robot.id}/telemetry/globalPosition`, ({ ralt }) => {
          altitudeRef.current.innerText = ralt.toFixed(2);
        })
      );
      subscribeTokens.push(
        EventEmitter.subscribe(`${robot.id}/telemetry/vfrHud`, ({ groundspeed }) => {
          speedRef.current.innerText = groundspeed.toFixed(2);
        })
      );
    }

    return () => {
      subscribeTokens.forEach((subscribeToken) => EventEmitter.unsubscribe(subscribeToken));
    };
  }, [showLabel]);

  return (
    <div className={cx('container')}>
      <div ref={robotRef} className={cx('iconWrapper')}>
        <div className={cx('icon')} style={{ transform: `scale(${scale})` }}>
          <SVG
            src={robot.model.iconUrl}
            width="48"
            height="48"
            fill={robot.color}
            stroke="rgba(255, 255, 255, 0.5)"
            strokeWidth={8}
          />
          <div className={cx('arrow')} />
        </div>
      </div>
      {showLabel && (
        <div className={cx('panel')}>
          <div className={cx('wrapper')}>
            <div className={cx('info')}>
              <span ref={altitudeRef}>N/A</span>
              <span className={cx('unit')}>m</span>
            </div>
            <div className={cx('info')}>
              <span ref={speedRef}>N/A</span>
              <span className={cx('unit')}>m/s</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Robot = ({ data: robot }) => {
  const dispatch = useDispatch();
  const store = useStore();

  useEffect(() => {
    const element = getMarkerElement();
    const subscribeToken = EventEmitter.subscribe(`${robot.id}/telemetry/globalPosition`, ({ lat, lng }) => {
      OlMap.addRobot(robot.id, element, { lat, lng });
    });

    element.addEventListener('click', () => {
      if (!robot.isOwned) return;

      dispatch(actions.robot.toggle(robot.id));
    });
    element.addEventListener('mouseenter', () => {
      dispatch(actions.callout.setText(robot.name));
    });
    element.addEventListener('mouseleave', () => {
      dispatch(actions.callout.setText(null));
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
      OlMap.removeRobot(robot.id);
    };
  }, [robot]);

  const getMarkerElement = () => {
    const content = document.createElement('div');

    createRoot(content).render(
      <Provider store={store}>
        <MarkerContent data={robot} />
      </Provider>
    );
    return content;
  };
};

export default Robot;
