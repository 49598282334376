import useMountEffect from '@restart/hooks/useMountEffect';
import React, { useState } from 'react';

import { Mode } from '@/components/ui/Dashboard';
import EventEmitter from '@/libs/EventEmitter';

const MODE = {
  0: 'Door Open',
  1: 'Door Closed',
  2: 'Door Opening',
  3: 'Door Closing',
  '-99': 'N/A',
};

const Component = ({ robotId }) => {
  const [mode, setMode] = useState();

  useMountEffect(() => {
    const subscribeToken = EventEmitter.subscribe(`${robotId}/telemetry/door`, (data) => {
      setMode(MODE[data.door]);
    });

    return () => {
      EventEmitter.unsubscribe(subscribeToken);
    };
  });

  return <Mode robotId={robotId} mode={mode} />;
};

export default Component;
