import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class UsvData extends MavLinkData {
  constructor() {
    super();
    this.oilcollMotorEncCnt = 0;
    this.oilcollMotorRotDir = 0;
    this.oilcollMotorRotSpd = 0;
    this.thrUtilRate = 0;
    this.heading = 0;
    this.speed = 0;
    this.latitude = 0;
    this.longitude = 0;
    this.bms1Soc = 0;
    this.bms1Vbat = 0;
    this.bms1Curr = 0;
    this.bms1TempMax = 0;
    this.bms1TempMin = 0;
    this.bms1VbatErr = 0;
    this.bms1TempErr = 0;
    this.bms1CurrErr = 0;
    this.bms1SocErr = 0;
    this.bms1DiffvErr = 0;
    this.bms1DifftErr = 0;
    this.bms1DefaultErr = 0;
    this.bms2Soc = 0;
    this.bms2Vbat = 0;
    this.bms2Curr = 0;
    this.bms2TempMax = 0;
    this.bms2TempMin = 0;
    this.bms2VbatErr = 0;
    this.bms2TempErr = 0;
    this.bms2CurrErr = 0;
    this.bms2SocErr = 0;
    this.bms2DiffvErr = 0;
    this.bms2DifftErr = 0;
    this.bms2DefaultErr = 0;
    this.powerAct = 0;
    this.posXError = 0;
    this.posYError = 0;
    this.angYawErr = 0;
  }
}

UsvData.MSG_ID = 1101;
UsvData.MSG_NAME = 'UsvData';
UsvData.PAYLOAD_LENGTH = 76;
UsvData.MAGIC_NUMBER = 229;
UsvData.FIELDS = [
  new MavLinkPacketField('oilcollMotorEncCnt', 'oilcollMotorEncCnt', 0, false, 4, 'int32_t', ''),
  new MavLinkPacketField('oilcollMotorRotDir', 'oilcollMotorRotDir', 4, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('oilcollMotorRotSpd', 'oilcollMotorRotSpd', 5, false, 4, 'uint8_t[]', ''),
  new MavLinkPacketField('thrUtilRate', 'thrUtilRate', 9, false, 4, 'int8_t[]', ''),
  new MavLinkPacketField('heading', 'heading', 13, false, 4, 'float', ''),
  new MavLinkPacketField('speed', 'speed', 17, false, 4, 'float', ''),
  new MavLinkPacketField('latitude', 'latitude', 21, false, 4, 'int32_t', ''),
  new MavLinkPacketField('longitude', 'longitude', 25, false, 4, 'int32_t', ''),
  new MavLinkPacketField('bms1Soc', 'bms1Soc', 29, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1Vbat', 'bms1Vbat', 31, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1Curr', 'bms1Curr', 33, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1TempMax', 'bms1TempMax', 35, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1TempMin', 'bms1TempMin', 37, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms1VbatErr', 'bms1VbatErr', 39, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1TempErr', 'bms1TempErr', 40, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1CurrErr', 'bms1CurrErr', 41, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1SocErr', 'bms1SocErr', 42, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1DiffvErr', 'bms1DiffvErr', 43, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1DifftErr', 'bms1DifftErr', 44, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms1DefaultErr', 'bms1DefaultErr', 45, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2Soc', 'bms2Soc', 46, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2Vbat', 'bms2Vbat', 48, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2Curr', 'bms2Curr', 50, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2TempMax', 'bms2TempMax', 52, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2TempMin', 'bms2TempMin', 54, false, 2, 'uint16_t', ''),
  new MavLinkPacketField('bms2VbatErr', 'bms2VbatErr', 56, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2TempErr', 'bms2TempErr', 57, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2CurrErr', 'bms2CurrErr', 58, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2SocErr', 'bms2SocErr', 59, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2DiffvErr', 'bms2DiffvErr', 60, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2DifftErr', 'bms2DifftErr', 61, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('bms2DefaultErr', 'bms2DefaultErr', 62, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('powerAct', 'powerAct', 63, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('posXError', 'posXError', 64, false, 4, 'float', ''),
  new MavLinkPacketField('posYError', 'posYError', 68, false, 4, 'float', ''),
  new MavLinkPacketField('angYawErr', 'angYawErr', 72, false, 4, 'float', ''),
];

export default UsvData;
