import { useEffect } from 'react';

import EventEmitter from '@/libs/EventEmitter';

const useWaitingRobotTimer = (robotId, dependencies) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      EventEmitter.publish(`${robotId}/event/waitingRobot`);
    }, 5000);

    return () => {
      EventEmitter.publish(`${robotId}/event/reconnectRobot`);
      clearTimeout(timer);
    };
  }, dependencies);
};

export default useWaitingRobotTimer;
