import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class StationCommand extends MavLinkData {
  constructor() {
    super();
    this.doorControl = 0;
    this.emgStop = 0;
    this.armingStatus = 0;
    this.winchPower = 0;
    this.uavRoll = 0;
    this.uavPitch = 0;
    this.cooler = 0;
  }
}

StationCommand.MSG_ID = 3001;
StationCommand.MSG_NAME = 'STATION_COMMAND';
StationCommand.PAYLOAD_LENGTH = 7;
StationCommand.MAGIC_NUMBER = 177;
StationCommand.FIELDS = [
  new MavLinkPacketField('door_control', 'doorControl', 0, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('emg_stop', 'emgStop', 1, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('arming_status', 'armingStatus', 2, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('winch_power', 'winchPower', 3, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('uav_roll', 'uavRoll', 4, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('uav_pitch', 'uavPitch', 5, false, 1, 'uint8_t', ''),
  new MavLinkPacketField('cooler', 'cooler', 6, false, 1, 'uint8_t', ''),
];

export default StationCommand;
