import { MavLinkData, MavLinkPacketField } from 'node-mavlink';

class GimbalCommand extends MavLinkData {
  constructor() {
    super();
    this.latitude = 0;
    this.longitude = 0;
    this.seaLevelAltitude = 0;
    this.relativeAltitude = 0;
    this.setDefaultLocation = 0;
  }
}

GimbalCommand.MSG_ID = 1002;
GimbalCommand.MSG_NAME = 'GIMBAL_COMMAND';
GimbalCommand.PAYLOAD_LENGTH = 13;
GimbalCommand.MAGIC_NUMBER = 163;
GimbalCommand.FIELDS = [
  new MavLinkPacketField('latitude', 'latitude', 0, false, 4, 'int32_t', ''),
  new MavLinkPacketField('longitude', 'longitude', 4, false, 8, 'int32_t', ''),
  new MavLinkPacketField('seaLevelAltitude', 'seaLevelAltitude', 8, false, 2, 'int16_t', ''),
  new MavLinkPacketField('relativeAltitude', 'relativeAltitude', 10, false, 2, 'int16_t', ''),
  new MavLinkPacketField('setDefaultLocation', 'setDefaultLocation', 12, false, 1, 'uint8_t', ''),
];

export default GimbalCommand;
