import { createActions } from 'redux-actions';

export default createActions(
  {
    RESET: () => ({}),
    LOAD_MAVLINK: (data) => ({ data }),
    LOAD_ROS: (data) => ({ data }),
    LOAD_EVENT: (data) => ({ data }),
    REMOVE: (robotId) => ({ robotId }),
  },
  { prefix: 'TELEMETRY' }
);
