import useMountEffect from '@restart/hooks/useMountEffect';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import { registerCustomMessageMagicNumber } from 'node-mavlink';
import { useSelector } from 'react-redux';

import * as quaternion from './messages';

import useWaitingRobotTimer from '@/hooks/useWaitingRobotTimer';
import EventEmitter from '@/libs/EventEmitter';

const Organizer = ({ data: robot }) => {
  const door = useSelector((state) => state.telemetry[robot.id]?.m3000);
  const drone = useSelector((state) => state.telemetry[robot.id]?.m3201);

  useWaitingRobotTimer(robot.id, [door]);

  useMountEffect(() => {
    // MavLink custom messages 정의
    try {
      registerCustomMessageMagicNumber('700', quaternion.GroundWeatherData.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('1000', quaternion.GimbalModeSetup.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('1002', quaternion.GimbalCommand.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('3000', quaternion.StationStatus.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('3001', quaternion.StationCommand.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('3200', quaternion.StationSendIsOn.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('3201', quaternion.StatationReceiveIsOn.MAGIC_NUMBER);
      registerCustomMessageMagicNumber('4901', quaternion.CameraCommand.MAGIC_NUMBER);
    } catch (e) {
      // TODO
    }
  });

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/door`, door);
  }, [door]);

  useUpdateEffect(() => {
    EventEmitter.publish(`${robot.id}/telemetry/drone`, drone);
  }, [drone]);

  return null;
};

export default Organizer;
