import useMountEffect from '@restart/hooks/useMountEffect';
import classNames from 'classnames/bind';
import React, { useState } from 'react';

import styles from './CookiePolicy.module.scss';

const cx = classNames.bind(styles);

const CookiePolicy = () => {
  const [isShow, setShow] = useState(false);

  useMountEffect(() => {
    const stamp = localStorage.getItem('@m1ucs/cookie');
    setShow(stamp === null);
  });

  const accept = (isAccept) => {
    localStorage.setItem('@m1ucs/cookie', `${isAccept}|${new Date().getTime()}`);
    setShow(false);
  };

  if (!isShow) return;

  return (
    <div className={cx('container')}>
      <div className={cx('wrapper')}>
        <div className={cx('content')}>
          <div className={cx('title')}>Your Cookie Controls</div>
          <p>
            M1UCS uses cookies to improve user experience and site performance. By clicking 'Accept All', you agree to
            our use of cookies. By clicking 'Reject All', we will not place cookies on your device unless strictly
            necessary for website functionality.
          </p>
        </div>
        <div className={cx('buttons')}>
          <button type="button" className={cx(['button', 'accent'])} onClick={() => accept(true)}>
            Accept All
          </button>
          <button type="button" className={cx('button')} onClick={() => accept(false)}>
            Reject All
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookiePolicy;
